import React from "react";
import { useFormikContext } from "formik";
export var FormikErrorFocus = function () {
    var _a = useFormikContext(), isSubmitting = _a.isSubmitting, isValidating = _a.isValidating, errors = _a.errors;
    var isObject = function (value) {
        return value && typeof value === "object" && value.constructor === Object;
    };
    var getKeysRecursively = function (object, depth) {
        if (depth === void 0) { depth = 0; }
        var currentKey;
        var arrayIndex;
        if (Array.isArray(object)) {
            var firstValue = object.find(function (el, index) {
                if (el) {
                    arrayIndex = index;
                    return el;
                }
            });
            return "[".concat(arrayIndex, "]") + getKeysRecursively(firstValue, depth + 1);
        }
        if (!isObject(object))
            return "";
        else
            currentKey = Object.keys(object)[0];
        if (!getKeysRecursively(object[currentKey], depth + 1)) {
            return "".concat(depth > 0 ? ".".concat(currentKey) : currentKey);
        }
        return currentKey + getKeysRecursively(object[currentKey], depth + 1);
    };
    var keys = Object.keys(errors);
    if (keys.length > 0 && isSubmitting && !isValidating) {
        var selectorKey = getKeysRecursively(errors);
        var selector = "[id=\"".concat(selectorKey, "\"], [name=\"").concat(selectorKey, "\"] ");
        var errorElement = document.querySelector(selector);
        if (errorElement) {
            errorElement === null || errorElement === void 0 ? void 0 : errorElement.focus();
        }
    }
    return React.createElement(React.Fragment, null);
};
