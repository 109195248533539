import {
  Request,
  RequestData,
  RequestStatus,
} from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { createContext, useContext, useEffect, useRef, useState } from "react";
import { fb, fstore } from "../../firebase-tools";
import { useMediaQuery, usePrevious } from "@max/common-ui";
import ldb from "../../ldb";
import { theme } from "../../theme";
import { DateTime } from "luxon";
import { removeLocalBlobsById } from "utils";
import { useUserContext } from "components/UserContextProvider";
import { useLocation, useParams } from "react-router";
import { EXPIRATION_DAYS_WARNING_THRESHOLD, getTimetoLive } from "./common";

export type ShoutoutRequest = {
  id: string;
  public: Request;
  private: RequestData;
  timeToLive?: string;
};

type MessageContextState = {
  isMountingVideoMessageUI;
  selectedMessage: ShoutoutRequest;
  userInitializedCamera: boolean;
  selectedTab: string;
  isLoadingMessages: boolean;
  messages: ShoutoutRequest[];
  sortCompletedMessagesBy: {
    id: string;
    text: string;
  };
  messageRequests: ShoutoutRequest[];
  messageQueueCount: number;
  completedMessagesCount: number;
  fileUploadProgress: any;
  setUserInitializedCamera: React.Dispatch<React.SetStateAction<boolean>>;
  onInitVideoRecorder: () => void;
  onMountingVideoRecorder: () => void;
  selectTab: (tab: any, isFocusingMessage?: boolean) => void;
  selectMessage: (message: any, isFocusingMessage?: boolean) => void;
  uploadFile: (messageRequestId: any) => Promise<void>;
  setCompletedMessageBy: React.Dispatch<
    React.SetStateAction<{
      id: string;
      text: string;
    }>
  >;
};

const MessagesContext = createContext<MessageContextState>(
  {} as MessageContextState
);

export const FADING_ANIMATION_LENGTH_SECONDS = 2;
export const DISAPPEARING_ANIMATION_LENGTH_SECONDS = 0.3;

export const TABS = {
  QUEUE: "QUEUE",
  COMPLETED: "COMPLETED",
};

export const SORT_TYPES = {
  recentSent: "recent_sent",
  oldestSent: "oldest_sent",
  recentRequest: "recent_request",
  oldestRequest: "oldest_request",
};

export const SORT_OPTIONS = [
  {
    id: SORT_TYPES.recentSent,
    text: "Sent Date (Most Recent)",
  },
  {
    id: SORT_TYPES.oldestSent,
    text: "Sent Date (Oldest)",
  },
  {
    id: SORT_TYPES.recentRequest,
    text: "Request Date (Most Recent)",
  },
  {
    id: SORT_TYPES.oldestRequest,
    text: "Request Date (Oldest)",
  },
];

export const MESSAGE_STATUS_LABEL = {
  PROCESSING: "Processing ...",
  UPLOADING: "Uploading ...",
  FAILED: "Failed",
  [RequestStatus.completed]: "Sent!",
  [RequestStatus.rejected]: "Declined",
};

const compareTimestamp = (a: DateTime, b: DateTime) =>
  a?.toMillis() > b?.toMillis() ? 1 : -1;

const compareCompletedMessages = (
  a: ShoutoutRequest,
  b: ShoutoutRequest,
  sortBy
) => {
  const compareBySentDate = (a, b, isAsc = true) => {
    if (a?.public?.sentAt && !b?.public?.sentAt) return isAsc ? -1 : 1;
    else if (!a?.public?.sentAt && b?.public?.sentAt) return isAsc ? 1 : -1;
    else if (!a?.public?.sentAt && !b?.public?.sentAt)
      return compareTimestamp(a?.public?.updatedAt, b?.public?.updatedAt);
    return compareTimestamp(a.public.sentAt, b.public.sentAt);
  };

  const { recentSent, oldestSent, recentRequest, oldestRequest } = SORT_TYPES;
  switch (sortBy) {
    case oldestSent:
      return compareBySentDate(a, b);
    case recentSent:
      return compareBySentDate(b, a, false);
    case oldestRequest:
      return compareTimestamp(a.public.createdAt, b.public.createdAt);
    case recentRequest:
      return compareTimestamp(b.public.createdAt, a.public.createdAt);
    default:
      return 1;
  }
};

const compareMessagesByTTL = (a: ShoutoutRequest, b: ShoutoutRequest) => {
  const aTTL = a.public.createdAt
    .plus({ days: a.public.shoutout.responseTimeDays })
    .diff(DateTime.now(), "days")
    .toObject().days;
  const bTTL = b.public.createdAt
    .plus({ days: b.public.shoutout.responseTimeDays })
    .diff(DateTime.now(), "days")
    .toObject().days;

  const requiresWarning = (ttl) =>
    0 < ttl && ttl < EXPIRATION_DAYS_WARNING_THRESHOLD;

  if (!requiresWarning(aTTL) && !requiresWarning(bTTL))
    compareTimestamp(b.public.createdAt, a.public.createdAt);
  if (requiresWarning(aTTL) && !requiresWarning(bTTL)) return -1;
  if (!requiresWarning(aTTL) && requiresWarning(bTTL)) return 1;
  return aTTL > bTTL ? 1 : -1;
};

const animationLength =
  FADING_ANIMATION_LENGTH_SECONDS + DISAPPEARING_ANIMATION_LENGTH_SECONDS;

const useMessageUploading = () => {
  const [fileUploadProgress, setFileUploadProgress] = useState({});
  const { user } = useUserContext();

  const fadeOutMessage = async (messageRequestId, status) => {
    setFileUploadProgress((state) => ({
      ...state,
      [messageRequestId]: {
        progress: 100,
        status: MESSAGE_STATUS_LABEL[status],
      },
    }));
    setTimeout(() => {
      setFileUploadProgress((state) => {
        const newValue = { ...state };
        delete newValue[messageRequestId];
        return newValue;
      });
    }, animationLength * 1000);
  };

  const uploadFile = async (messageRequestId) => {
    const doc = await ldb.collection("blobs").doc(messageRequestId).get();
    if (!doc) return;
    const { shoutoutId, data: blob } = doc;
    const mimeType = blob.type;
    const ext = mimeType.split(";")[0].split("/")[1];
    const storageRef = fb
      .storage()
      .ref(
        `shoutouts/${shoutoutId}/unprocessed/${user.uid}/${messageRequestId}.${ext}`
      );
    const task = storageRef.put(blob);

    const onUploadProgress = (snapshot) => {
      const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
      setFileUploadProgress((state) => ({
        ...state,
        [messageRequestId]: {
          progress,
          status: MESSAGE_STATUS_LABEL.UPLOADING,
        },
      }));
    };

    const onUploadError = () => {
      setTimeout(() => {
        uploadFile(messageRequestId);
      }, 2000);
    };

    const onUploadComplete = () => {
      setFileUploadProgress((state) => ({
        ...state,
        [messageRequestId]: {
          progress: 100,
          status: MESSAGE_STATUS_LABEL.PROCESSING,
        },
      }));
    };

    task.on("state_changed", onUploadProgress, onUploadError, onUploadComplete);
  };

  return {
    fileUploadProgress,
    fadeOutMessage,
    uploadFile,
  };
};

const useMessageRequests = () => {
  const { user, isAdmin } = useUserContext();
  const { artistGroupId, shoutoutId } = useParams<any>();
  const [requests, setRequests] = useState<ShoutoutRequest[] | null>(null);
  const [requestsPrivateDocs, setRequestsPrivateDocs] = useState(null);
  const [requestsPublicDocs, setRequestsPublicDocs] = useState(null);
  const requestsPrivateDocsUnsubscribe = useRef(null);
  const requestsPublicDocsUnsubscribe = useRef(null);

  useEffect(() => {
    return () => {
      requestsPrivateDocsUnsubscribe.current();
      requestsPublicDocsUnsubscribe.current();
    };
  }, []);

  useEffect(() => {
    const uId = user?.uid;
    if (uId) {
      let queryPublic = fstore
        .collection("shoutout_requests")
        .where("shoutout.artistGroupId", "==", artistGroupId);
      if (!isAdmin)
        queryPublic = queryPublic.where("fulfillerUids", "array-contains", uId);
      const unsubscribePublic = queryPublic.onSnapshot((snap) => {
        setRequestsPublicDocs(snap.docs);
      });
      requestsPublicDocsUnsubscribe.current = unsubscribePublic;

      let queryPrivate = fstore.collectionGroup("private");
      if (!isAdmin)
        queryPrivate = queryPrivate
          .where("shoutoutId", "!=", "")
          .where("fulfillerUids", "array-contains", uId);
      const unsubscribePrivate = queryPrivate.onSnapshot((snap) => {
        setRequestsPrivateDocs(snap.docs);
      });
      requestsPrivateDocsUnsubscribe.current = unsubscribePrivate;
    }
  }, [isAdmin, user?.uid, artistGroupId, shoutoutId]);

  useEffect(() => {
    if (requestsPublicDocs && requestsPrivateDocs) {
      const _requests = [];

      requestsPublicDocs?.forEach((r) => {
        try {
          _requests.push({ id: r.id, public: Request.raw(r.data()) });
        } catch (err) {
          console.log(r.id);
          console.log(err);
          console.log(err?.errors);
        }
      });

      requestsPrivateDocs
        ?.filter((d) => d.id === "data")
        .forEach((r) => {
          const reqId = r.ref.parent.parent.id;
          try {
            const request = _requests.find((_r) => _r.id === reqId);
            if (request) request.private = RequestData.raw(r.data());
          } catch (err) {
            console.log(reqId);
            console.log(err);
            console.log(err?.errors);
          }
        });

      setRequests(_requests.filter((r) => r.id && r.public && r.private));
    }
  }, [requestsPublicDocs, requestsPrivateDocs]);

  return {
    messageRequests: requests ? requests : [],
    isLoading: !requests,
  };
};

export const messageIsCompleted = (m) => {
  if (
    m.public.status === RequestStatus.completed ||
    m.public.status === RequestStatus.rejected
  )
    return true;
  if (
    m.public.status === RequestStatus.refunded &&
    m.private.previousStatus === RequestStatus.completed
  )
    return true;
  return false;
};

export const messageIsDone = (m) => {
  const statuses = [
    RequestStatus.completed,
    RequestStatus.rejected,
    RequestStatus.expired,
    RequestStatus.refunded,
  ];
  return (
    statuses.includes(m.public.status) ||
    statuses.includes(m.private.previousStatus)
  );
};

const getMessagesCount = (messages, shoutoutId) =>
  shoutoutId
    ? messages.filter((m) => m.public.shoutoutId === shoutoutId).length
    : messages.length;

export const MessagesContextProvider = ({ children }) => {
  const [selectedTab, setSelectedTab] = useState(TABS.QUEUE);
  const [userInitializedCamera, setUserInitializedCamera] = useState(false);
  const [sortCompletedMessagesBy, setCompletedMessageBy] = useState(
    SORT_OPTIONS[0]
  );
  const [state, setState] = useState({
    selectedMessageId: null,
    isMountingVideoMessageUI: false,
    isFocusingMessage: false,
  });
  const { state: routerState } = useLocation<any>();
  const {
    messageRequests,
    isLoading: isLoadingMessageRequests,
  } = useMessageRequests();
  const { shoutoutId } = useParams<any>();

  const {
    selectedMessageId,
    isMountingVideoMessageUI,
    isFocusingMessage,
  } = state;
  const [displayedMessageQueue, setDisplayedMessageQueue] = useState<
    ShoutoutRequest[]
  >([]);

  const {
    fileUploadProgress,
    uploadFile,
    fadeOutMessage,
  } = useMessageUploading();

  const messageQueue = messageRequests
    .filter((m) => !messageIsDone(m))
    .map((m) => ({
      ...m,
      timeToLive: getTimetoLive(
        m.public.createdAt,
        m.public.shoutout.responseTimeDays
      ).toObject(),
    }))
    .sort((a, b) => {
      return compareMessagesByTTL(a, b);
    });
  const messageQueueCount = getMessagesCount(messageQueue, shoutoutId);

  const completedMessages: ShoutoutRequest[] = messageRequests.filter((m) =>
    messageIsDone(m)
  );
  const completedMessagesCount = getMessagesCount(
    completedMessages,
    shoutoutId
  );
  completedMessages.sort((a, b) =>
    compareCompletedMessages(a, b, sortCompletedMessagesBy.id)
  );
  removeLocalBlobsById(completedMessages.map((m) => m.id));

  const messageQueueIds = messageQueue.map((msg) => msg.id);
  const prevMessageQueueIds: any = usePrevious(messageQueueIds);
  useEffect(() => {
    if (isLoadingMessageRequests) return;
    if (prevMessageQueueIds?.length > messageQueueIds?.length) {
      const completedMessageIds = prevMessageQueueIds?.filter(
        (id) => !messageQueueIds.includes(id)
      );
      completedMessageIds?.forEach((completedMessageId) => {
        fadeOutMessage(
          completedMessageId,
          messageRequests?.find((m) => m.id === completedMessageId)?.public
            ?.status
        );
      });

      setTimeout(() => {
        setDisplayedMessageQueue(messageQueue);
        completedMessageIds?.forEach((completedMessageId) => {
          if (completedMessageId === selectedMessageId)
            setState({
              selectedMessageId: null,
              isMountingVideoMessageUI: false,
              isFocusingMessage: false,
            });
        });
      }, animationLength * 1000);
    } else {
      const isFadingOutMessage =
        Object.keys(fileUploadProgress).filter((key) =>
          [
            MESSAGE_STATUS_LABEL.completed,
            MESSAGE_STATUS_LABEL.rejected,
          ].includes(fileUploadProgress[key].status)
        ).length > 0;
      if (!isFadingOutMessage) setDisplayedMessageQueue(messageQueue);
    }
  }, [messageRequests, fileUploadProgress]);

  const initializingMedia =
    selectedTab === TABS.QUEUE &&
    userInitializedCamera &&
    isMountingVideoMessageUI;

  const selectTab = (tab, isFocusingMessage = false) => {
    if (initializingMedia) return;
    setState({
      selectedMessageId: null,
      isMountingVideoMessageUI: false,
      isFocusingMessage,
    });
    setSelectedTab(tab);
  };

  const selectMessage = (message, isFocusingMessage = false) => {
    if (!message) {
      setState({
        selectedMessageId: null,
        isMountingVideoMessageUI: false,
        isFocusingMessage: false,
      });
      return;
    }
    if (initializingMedia) return;
    setState({
      selectedMessageId: null,
      isMountingVideoMessageUI: true,
      isFocusingMessage,
    });
    setTimeout(() => {
      setState({
        selectedMessageId: message.id,
        isMountingVideoMessageUI: true,
        isFocusingMessage: false,
      });
    }, 300);
  };

  const onMountingVideoRecorder = () =>
    setState({
      selectedMessageId,
      isMountingVideoMessageUI: true,
      isFocusingMessage: false,
    });

  const onInitVideoRecorder = () =>
    setState({
      selectedMessageId,
      isMountingVideoMessageUI: false,
      isFocusingMessage: false,
    });

  const messages =
    selectedTab === TABS.QUEUE ? displayedMessageQueue : completedMessages;

  const selectedMessage = messages.find((m) => m.id === selectedMessageId);

  const shoutoutMessages = messages.filter(
    (m) => m.public.shoutoutId === shoutoutId
  );

  const filteredMessages = shoutoutId ? shoutoutMessages : messages;
  const selectDefaultMessage = () => {
    for (let i = 0; i < filteredMessages.length; i++)
      if (filteredMessages[i].public.status !== RequestStatus.processing) {
        selectMessage(filteredMessages[i]);
        break;
      }
  };
  const isMobile = useMediaQuery(theme.media.mobile);
  const shouldSelectDefaultMessage =
    !isMobile &&
    !isMountingVideoMessageUI &&
    !selectedMessageId &&
    !isFocusingMessage &&
    messages.length > 0;
  if (shouldSelectDefaultMessage) selectDefaultMessage();

  useEffect(() => {
    if (routerState?.isFocusingMessage) return;
    if (shouldSelectDefaultMessage) selectDefaultMessage();
    else selectMessage(null);
  }, [shoutoutId]);

  return (
    <MessagesContext.Provider
      value={{
        isMountingVideoMessageUI,
        selectedMessage,
        userInitializedCamera,
        selectedTab,
        messageRequests,
        isLoadingMessages: isLoadingMessageRequests,
        messages: filteredMessages,
        sortCompletedMessagesBy,
        messageQueueCount,
        completedMessagesCount,
        fileUploadProgress,
        setUserInitializedCamera,
        onInitVideoRecorder,
        onMountingVideoRecorder,
        selectTab,
        selectMessage,
        uploadFile,
        setCompletedMessageBy,
      }}
    >
      {children}
    </MessagesContext.Provider>
  );
};

export const useMessagesContext = () => useContext(MessagesContext);
