import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import {
  OverlayBackdrop as Backdrop,
  Overlay as DropdownDiv,
} from "@max/common-ui";
import { Icon, P, Input, LoaderInline } from "notes";
import _ from "lodash";
import { fstore } from "../../../../../firebase-tools";
import { Accordion } from "./Accordion";
import parser from "./parser";
import { useMediaQuery, useWindowInnerHeight } from "@max/common-ui";
import { theme } from "../../../../../theme";
import { Div, scrollBarDarkCss } from "@max/common-ui";

const horizontalPaddingCss = css`
  padding: 0 20px 0 20px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0 15px 0 15px;
  }
`;

const Dropdown = styled(DropdownDiv)`
  width: 450px;
  height: 550px;
  margin-top: -18px;
  margin-left: -400px;
  color: black;
  @media all and ${(props) => props.theme.media.mobile} {
    position: fixed;
    top: 0;
    left: 0;
    margin: 0;
    height: 100vh;
    width: 100vw;
    border-radius: 0;
    height: ${(props) => props.innerHeight}px;
    input {
      outline: none !important;
      border: none;
      &:focus {
        border-color: none;
        box-shadow: none;
      }
      &:disabled {
        border-color: none;
      }
    }
  }
`;

const InputWrapper = styled(Div)`
  padding-bottom: 15px;
  border-bottom: 1px solid #e2e6e8;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0 20px 0 0;
    height: 50px;
    display: flex;
    align-items: center;
  }
`;

const HeaderContainer = styled(Div)`
  ${horizontalPaddingCss}
  @media all and ${(props) => props.theme.media.mobile} {
    padding:0;
  }
`;

const Wrapper = styled(Div)`
  display: grid;
  grid-template-rows: auto 1fr auto;
  height: 100%;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 0;
  }
`;

const NotFound = styled.div`
  margin-top: 12px;
  color: #596266;
  font-size: 17px;
  line-height: 27px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding: 20px;
  }
`;

const SectionContentWrapper = styled.div`
  padding-top: 24px;
`;

const HelpTopics = styled(Div)`
  ${horizontalPaddingCss}
  overflow-y: scroll;
  ${scrollBarDarkCss}
  @media only screen and ${(props) => props.theme.media.small} {
  }
`;

const BackIcon = styled(Div)`
  height: 47px;
  width: 47px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media all and ${(props) => props.theme.media.desktop} {
    display: none;
  }
`;

const HelpModal = ({ onClose }) => {
  const windowInnerHeight = useWindowInnerHeight();
  return (
    <>
      <Div>
        <Backdrop onClick={onClose} />
        <Dropdown innerHeight={windowInnerHeight}>
          <Help onClose={onClose} />
        </Dropdown>
      </Div>
    </>
  );
};

const Help = ({ onClose }) => {
  // const history = useHistory();
  const [filter, setFilter] = useState("");
  const [topics, setTopics] = useState({});
  const [filteredTopics, setFilteredTopics] = useState({});
  const [sections, setSections] = useState({});
  const [filteredSections, setFilteredSections] = useState({});
  const [ready, setReady] = useState(false);
  const isMobile = useMediaQuery(theme.media.mobile);

  useEffect(() => {
    if (!_.keys(topics).length) {
      init();
    }
    return () => {
      setFilter("");
      setFilteredTopics(topics);
    };
  }, []);

  useEffect(() => {
    if (filter.replace(/\s/gi, "") === "" || filter.length < 3) {
      resetTopics(false);
      return;
    }
    _.debounce(filterTopics, 200, { maxWait: 1000 })();
  }, [filter]);

  const init = async () => {
    setReady(false);
    let snap = await fstore
      .collection("faq")
      .doc("sections")
      .collection("list")
      .orderBy("sort")
      .get();
    const results = {};
    const topicResults = {};
    if (snap.docs) {
      snap.docs.map((s) => {
        try {
          results[s.data().key] = {
            title: s.data().title,
            topics: [],
          };
        } catch (err) {
          console.log(err);
        }
      });
      snap = await fstore
        .collection("faq")
        .doc("topics")
        .collection("shoutout_list")
        .get();
      snap.docs.map((s) => {
        try {
          s.data().sections?.forEach((section) => {
            results[section].topics.push(s.id);
          });
          topicResults[s.id] = s.data();
        } catch (err) {
          console.log(err);
        }
      });
    }
    setSections(results);
    setFilteredSections(results);
    setTopics(topicResults);
    setFilteredTopics(topicResults);
    setReady(true);
  };

  const resetTopics = (resetFilter = true) => {
    setFilteredSections(sections);
    setFilteredTopics(topics);
    if (resetFilter) {
      setFilter("");
    }
  };

  const filterTopics = () => {
    const regex = new RegExp(filter, "gi");
    const sectionResults = {};
    const results = {};
    Object.keys(topics).filter((key) => {
      const topic = topics[key];
      if (
        regex.test(_.get(topic, "title", "")) ||
        regex.test(_.get(topic, "content", "")) ||
        regex.test(_.get(topic, "keywords", ""))
      ) {
        topics[key].sections?.forEach((section) => {
          if (!sectionResults[section]) {
            sectionResults[section] = {
              title: sections[section].title,
              topics: [],
            };
          }
          sectionResults[section].topics.push(key);
        });
        results[key] = topics[key];
      }
    });
    setFilteredSections(sectionResults);
    setFilteredTopics(results);
  };

  const inputUI = (
    <InputWrapper dflex>
      <BackIcon clickable alignCenter onClick={onClose}>
        <Icon name="Left" />
      </BackIcon>
      <Input
        name="filter"
        value={filter}
        onChange={(value) => setFilter(value)}
        placeholder="Search for a question or topic by keyword"
        style={{ maxHeight: "3em" }}
        leftIcon={!isMobile ? <Icon form name="Search" /> : null}
        rightIcon={
          !!filter && filter.length > 0 ? (
            <Icon form name="RemoveCircle" onClick={() => resetTopics()} />
          ) : null
        }
      />
    </InputWrapper>
  );

  const headerUI = (
    <HeaderContainer>
      <Div forDesktop spaceBetween alignCenter pt_17 pb_17>
        <Div fs_25 bold>
          Help
        </Div>
        <Div clickable onClick={onClose}>
          <Icon platform name="Close" />
        </Div>
      </Div>
      {inputUI}
    </HeaderContainer>
  );

  const helpTopicsUI = (
    <HelpTopics>
      {filteredTopics && _.keys(filteredTopics).length > 0 ? (
        _.keys(filteredTopics).map((key) => (
          <Div key={key}>
            <Accordion title={filteredTopics[key].title}>
              <SectionContentWrapper>
                {parser(filteredTopics[key].content)}
              </SectionContentWrapper>
            </Accordion>
          </Div>
        ))
      ) : (
        <NotFound>
          We were unable to locate any matches for your search criteria. Please
          try a new search.
        </NotFound>
      )}
    </HelpTopics>
  );

  return (
    <Wrapper>
      {headerUI}
      {ready ? (
        helpTopicsUI
      ) : (
        <Div mt_40 textAlignCenter>
          <P>Loading Help...</P>
          <LoaderInline />
        </Div>
      )}
    </Wrapper>
  );
};
export default HelpModal;
