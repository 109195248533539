import { useUser } from "auth";
import LogRocket from "logrocket";
import firebase from "firebase-tools";
import { createContext, useContext, useEffect, useState } from "react";

// All props **except** functions
export type NonFunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]: T[K] extends Function ? never : K;
}[keyof T];

// **Only ** function props
export type FunctionPropertyNames<T> = {
  // eslint-disable-next-line @typescript-eslint/ban-types
  [K in keyof T]?: T[K] extends Function ? K : never;
}[keyof T];

export type FunctionsOptional<T> = Partial<Pick<T, FunctionPropertyNames<T>>> &
  Pick<T, NonFunctionPropertyNames<T>>;

type State = {
  user: FunctionsOptional<firebase.User>;
  isAdmin: boolean;
  isArtistGroupAdmin: boolean;
  isLoggedIn: boolean;
};

const UserContext = createContext<State>({} as State);

export const UserContextProvider = ({ children }) => {
  const { user } = useUser();
  const [isAdmin, setIsAdmin] = useState(null);
  const [isArtistGroupAdmin, setIsArtistGroupAdmin] = useState(false);


  useEffect(() => {
    if (user && user.getIdTokenResult) {
      (async () => {
        try {
          const res = await user.getIdTokenResult();
          if (res?.claims) setIsAdmin(res.claims.admin);
        } catch (err) {
          console.log(err);
        }
      })();
    }
  }, [user]);


  useEffect(() => {
    LogRocket.identify(user?.uid, {
      name: user?.displayName,
      email: user?.email,
      phone: user?.phoneNumber,
    });
  }, [user]);

  const value = {
    user,
    isAdmin,
    isArtistGroupAdmin,
    isLoggedIn: !!user,
  };
  return <UserContext.Provider value={value}>{children}</UserContext.Provider>;
};

export const useUserContext = () => useContext(UserContext);
