import {
  CheckboxInput,
  Div,
  ErrorMessage,
  RadioInput,
  SelectImageFields,
} from "@max/common-ui";
import { useFormikContext } from "formik";
import { useParams } from "react-router-dom";
import { Section } from "../common";
import {
  headerStylingOptions,
  HeaderStylingTypes,
} from "../common/initialValues";
import { LaunchCampaignButton } from "../LaunchButton";

const header = "Media";
const description = "Customize the look and feel of your ShoutOuts.";

const imageFields = [
  {
    name: "desktopImg",
    label: "Header image (desktop)",
    aspectRatio: 2 / 1,
    description:
      "Upload an image to use as the header image on the desktop view of your store. An image that does NOT have text will work best. The image uploader below will allow you to crop your image to size.",
    dropzoneDescription:
      "(High-resolution images of 1000 x 480 pixels or with an aspect ratio of 2:1 are recommended)",
    width: "60%",
  },
  {
    name: "mobileImg",
    label: "Header image (mobile)",
    aspectRatio: 1 / 1,
    description:
      "Upload an image to use as the header image on the mobile view of your store and in emails fans receive. An image that does NOT have text will work best. The image uploader below will allow you to crop your image to size.",
    dropzoneDescription:
      "(High-resolution images of 500 x 500 pixels or with an aspect ratio of 1:1 are recommended)",
    width: "30%",
  },
];

const MediaFields = () => {
  const { values, setValues, submitCount } = useFormikContext<any>();
  const { shoutoutId } = useParams<any>();
  const isEdit = !!shoutoutId;

  const headerStylingUI = (
    <Div mt_5>
      <Div fs_17 extraBold mb_10 c_text mt_30>
        Header bar styling
      </Div>
      <RadioInput name="headerStyling" options={headerStylingOptions} />
      {values.headerStyling === HeaderStylingTypes.custom && (
        <Div mt_25 dflex>
          <Div mb_10 mr_10>
            Select custom color:
          </Div>
          <input
            value={values.headerBackgroundColor}
            type="color"
            onChange={(e) =>
              setValues({ ...values, headerBackgroundColor: e.target.value })
            }
          />
        </Div>
      )}
      <Div mt_50>
        <CheckboxInput
          name="hideSetliveHeaderLogo"
          description="Hide Set.live logo from header"
        />
      </Div>
    </Div>
  );

  const { desktopImg, mobileImg } = values;
  return (
    <Section withMargin header={header} description={description}>
      <SelectImageFields
        values={{ desktopImg, mobileImg }}
        setValues={(v) => {
          setValues((p) => ({ ...p, ...v }));
        }}
        fields={imageFields}
      />
      {submitCount > 0 && !values.desktopImg.blob && (
        <Div mt_35 mb_n20 id="desktopImg" tabIndex="0">
          <ErrorMessage msg={"Header image (desktop) cannot be blank"} />
        </Div>
      )}
      {submitCount > 0 && !values.mobileImg.blob && (
        <Div mt_35 mb_n20 id="desktopImg" tabIndex="0">
          <ErrorMessage msg={"Header image (mobile) cannot be blank"} />
        </Div>
      )}

      {headerStylingUI}
      <Div forDesktop mt_70>
        <LaunchCampaignButton isEdit={isEdit} />
      </Div>
    </Section>
  );
};

export default MediaFields;
