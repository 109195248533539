import styled from "styled-components";
import { useEffect, useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { Icon } from "notes";
import { Div } from "@max/common-ui";

const ToClipboardWrapper = styled(Div)`
  border-radius: 4px;
  width: 100%;
  height: 45px;
  background-color: white;
  box-shadow: 0 8px 8px 0 rgba(0, 0, 0, 0.24), 0 0 8px 0 rgba(0, 0, 0, 0.32);
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.9;
  }
`;

const ToClipboardText = styled(Div)`
  width: 85%;
  overflow: hidden;
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
`;

const CopiedToClipboardContainer = styled(Div)`
  background-color: #07090f;
  font-size: 12px;
  padding: 5px;
  font-weight: 600;
  position: absolute;
  margin-top: -70px;
  border-radius: 4px;
  color: white;
`;

const CopyToClipboardIcon = styled(Div)`
  :hover {
    cursor: pointer;
    svg {
      path {
        fill: #1f1f1f;
      }
    }
  }
`;

const ToClipboard = ({ url }) => {
  const [copiedToClipboard, setCopiedToClipboard] = useState(false);
  const [timeoutId, settimeoutId] = useState(null);

  useEffect(() => {
    return () => {
      if (timeoutId) clearTimeout(timeoutId);
    };
  }, []);

  const onCopy = () => {
    setCopiedToClipboard(true);
    const tid = setTimeout(() => {
      setCopiedToClipboard(false);
    }, 2000);
    settimeoutId(tid);
  };

  return (
    <CopyToClipboard onCopy={onCopy} text={url}>
      <ToClipboardWrapper mt_24 centered>
        <Div w_40 mt_6 centered>
          <CopyToClipboardIcon>
            <Icon name="Duplicate" />
            {copiedToClipboard && (
              <CopiedToClipboardContainer>
                Copied to clipboard!
              </CopiedToClipboardContainer>
            )}
          </CopyToClipboardIcon>
        </Div>
        <ToClipboardText>{url}</ToClipboardText>
      </ToClipboardWrapper>
    </CopyToClipboard>
  );
};

export default ToClipboard;
