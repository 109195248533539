import { ShoutoutAllocationMode } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { fb } from "firebase-tools";
import { DateTime } from "luxon";
import { getBlobFromUrl } from "utils";

export const responseTimeOptions = [
  { id: "7", text: "7 days" },
  { id: "10", text: "10 days" },
];

export const campaignTypeOptions = [
  {
    label: "Unlimited",
    value: ShoutoutAllocationMode.unlimited,
    description:
      "Campaign has no end date or limit to the number of requests accepted. Campaign will run indefinitely.",
  },
  {
    label: "Date",
    value: ShoutoutAllocationMode.date,
    description:
      "Campaign will stop accepting fan requests on a selected date, regardless of the number of submissions.",
  },
  {
    label: "Quantity",
    value: ShoutoutAllocationMode.quantity,
    description:
      "Campaign will end once a specified number of requests have been submitted, regardless of campaign duration.",
  },
];

export const conversationStarterOptions = [
  { value: "A special hello", label: "A special hello" },
  {
    value: "A question about a song or album",
    label: "A question about a song or album",
  },
  {
    value: "Message for a special occasion",
    label: "Message for a special occasion",
  },
  { value: "Some quick advice", label: "Some quick advice" },
  {
    value: "A short story from a recent tour",
    label: "A short story from a recent tour",
  },
  {
    value: "Some music or pop culture recommendations",
    label: "Some music or pop culture recommendations",
  },
  { value: "Your choice! Surprise me!", label: "Your choice! Surprise me!" },
  {
    value: "Other",
    label: "Other (custom)",
  },
];

export const conversationStarterInitialValues = [
  "A special hello",
  "A question about a song or album",
  "Message for a special occasion",
  "Some quick advice",
  "A short story from a recent tour",
  "Some music or pop culture recommendations",
  "Your choice! Surprise me!",
];

export enum HeaderStylingTypes {
  default = "default",
  custom = "custom",
}

export const headerStylingOptions = [
  {
    label: "Default gradient",
    value: HeaderStylingTypes.default,
  },
  {
    label: "Custom",
    value: HeaderStylingTypes.custom,
  },
];

export const campaignFormInitialValues = (artistGroupName) => ({
  name: artistGroupName,
  type: ShoutoutAllocationMode.unlimited,
  hideSetliveHeaderLogo: false,
  headerStyling: "default",
  introduction: `Fill out this page to customize your message from ${artistGroupName}`,
  unitPrice: "",
  responseTimeDays: responseTimeOptions[0].id,
  rules: `Please do not ask ${artistGroupName} to endorse a product. The artist may respond to your prompt with a message of their choosing.`,
  starters: conversationStarterInitialValues,
  maxNumberRequests: "",
  campaignEndDate: null,
  customStarter: null,
  desktopImg: { blob: null, isInitialValue: true },
  mobileImg: { blob: null, isInitialValue: true },
});

export const getInitialValuesFromShoutout = async (shoutout) => {
  const starters = shoutout.fields.starters;
  const customStarter = shoutout.fields.starters.find(
    (s) => !conversationStarterOptions.map((v) => v.value).includes(s)
  );
  if (customStarter) starters.push("Other");
  return {
    name: shoutout.name,
    type: shoutout.allocationMode,
    headerStyling: shoutout.headerBackgroundColor
      ? HeaderStylingTypes.custom
      : HeaderStylingTypes.default,
    headerBackgroundColor: shoutout.headerBackgroundColor,
    introduction: shoutout.introduction,
    unitPrice: (shoutout.unitPrice / 100).toString(),
    hideSetliveHeaderLogo: shoutout.hideSetliveHeaderLogo,
    responseTimeDays: shoutout.responseTimeDays,
    rules: shoutout.rules,
    starters: shoutout.fields.starters.filter((v) => v !== customStarter),
    maxNumberRequests: shoutout?.quantity?.limit,
    campaignEndDate: shoutout.endDate
      ? new Date(shoutout.endDate.seconds * 1000)
      : null,
    customStarter: customStarter || null,
    desktopImg: {
      blob: await getBlobFromUrl(shoutout.assets.headerLarge.path),
      isInitialValue: true,
    },
    mobileImg: {
      blob: await getBlobFromUrl(shoutout.assets.headerSmall.path),
      isInitialValue: true,
    },
  };
};

export const getShoutoutPayload = (values, urls, isEdit) => {
  const res: any = {
    name: values.name || "",
    unitPrice: parseFloat(values.unitPrice) * 100,
    introduction: values.introduction,
    responseTimeDays: parseInt(values.responseTimeDays),
    rules: values.rules,
    allocationMode: values.type,
    hideSetliveHeaderLogo: values.hideSetliveHeaderLogo || false,
    quantity: {},
    fields: {
      starters: values.starters.map((v) =>
        v === "Other" ? values.customStarter : v
      ),
    },
    assets: {
      headerLarge: { path: urls.desktopImgUrl },
      icon: { path: urls.mobileImgUrl },
      headerSmall: { path: urls.mobileImgUrl },
      video: { path: urls.videoUrl || "" },
    },
  };

  if (values.campaignEndDate)
    res.endDate = isEdit
      ? values.campaignEndDate
      : DateTime.fromMillis(values.campaignEndDate.getTime());
  else if (isEdit) res.endDate = fb.firestore.FieldValue.delete();

  if (values.maxNumberRequests)
    res.quantity.limit = parseInt(values.maxNumberRequests);
  else if (isEdit) res.quantity.limit = fb.firestore.FieldValue.delete();

  if (values.headerStyling === HeaderStylingTypes.custom)
    res.headerBackgroundColor = values.headerBackgroundColor || "#000000";
  else if (isEdit) res.headerBackgroundColor = fb.firestore.FieldValue.delete();

  return res;
};
