import styled from "styled-components";
import {
  OverlayBackdrop as Backdrop,
  Overlay as DropdownDiv,
} from "@max/common-ui";
import { useUser } from "auth";
import { fb } from "../../firebase-tools";
import { useState } from "react";
import { Icon } from "notes";
import { useUserContext } from "components/UserContextProvider";
import { ROUTES } from "components/Routes";
import { generatePath, useHistory, useParams } from "react-router";
import { Div, useIsMobile } from "@max/common-ui";

const UserMenuWrapper = styled(Div)`
  display: flex;
`;

const Dropdown = styled(DropdownDiv)`
  width: 200px;
  border-radius: 0px;
  margin-left: -200px;
  margin-top: 45px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 35px;
  }
`;

const UserMenu = () => {
  const { user, isAdmin } = useUserContext();
  const [isUserProfileDropdownOpen, setIsUserProfileDropdownOpen] = useState(
    false
  );
  const isMobile = useIsMobile();
  const history = useHistory();
  const { signOut } = useUser();

  const { artistGroupId } = useParams<any>();

  const _signOut = async () => {
    await signOut();
    await fb.auth().signOut();
    setIsUserProfileDropdownOpen(false);
  };

  const gotoPage = (path) => {
    history.push(path);
    setIsUserProfileDropdownOpen(false);
  };

  const dropdownUI = (
    <Div>
      {isUserProfileDropdownOpen && (
        <>
          <Backdrop onClick={() => setIsUserProfileDropdownOpen(false)} />
          <Dropdown>
            <Div w100>
              <UserMenuItem onClick={() => gotoPage(ROUTES.ARTISTS_GROUPS)}>
                <Div ml_2>{isAdmin ? "See All" : "My"} Artists</Div>
              </UserMenuItem>
              {artistGroupId && (
                <UserMenuItem
                  onClick={() =>
                    gotoPage(
                      generatePath(ROUTES.NEW_CAMPAIGN_FORM, { artistGroupId })
                    )
                  }
                >
                  <Div ml_2>Plan New Campaign</Div>
                </UserMenuItem>
              )}
              <UserMenuItem onClick={() => gotoPage(ROUTES.ARTIST_MANAGEMENT)}>
                <Div ml_2>Artist Management</Div>
              </UserMenuItem>
              <UserMenuItem
                onClick={() => {
                  window.location.href =
                    "https://" + process.env.REACT_APP_FIREBASE_AUTHDOMAIN;
                }}
              >
                <Div ml_2>My Account</Div>
              </UserMenuItem>
            </Div>
            <Div w100>
              <UserMenuItem onClick={_signOut}>
                <Icon tertiary name="Exit" />
                <Div ml_10>Log Out</Div>
              </UserMenuItem>
            </Div>
          </Dropdown>
        </>
      )}
    </Div>
  );

  const dropdownIconDim = isMobile ? "13px" : "16px";
  return (
    <UserMenuWrapper>
      <UserProfileWrapper
        onClick={() => {
          setIsUserProfileDropdownOpen(true);
        }}
      >
        <Div mr_10 centered h100>
          {user.photoURL ? (
            <Div centered>
              <Avatar src={user.photoURL} />
            </Div>
          ) : (
            <Placeholder centered>
              {user.displayName
                ? user.displayName[0]
                : user.email[0].toUpperCase()}
            </Placeholder>
          )}
        </Div>
        <Div alignCenter>
          <Icon
            indicator
            name="Dropdown"
            height={dropdownIconDim}
            width={dropdownIconDim}
          />
        </Div>
      </UserProfileWrapper>
      {dropdownUI}
    </UserMenuWrapper>
  );
};

const DisplayName = styled(Div)`
  border-bottom: solid 1px transparent;
`;

const UserProfileWrapper = styled(Div)`
  svg {
    path {
      fill: currentColor;
    }
  }
  display: flex;
  align-items: center;
  font-weight: bold;
  user-select: none;
  ${DisplayName} {
    border-bottom: solid 2px transparent;
  }

  :hover {
    opacity: 0.9;
    cursor: pointer;
    ${DisplayName} {
      border-bottom: solid 2px white;
    }
  }
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 0;
    align-items: center;
    text-align: center;
    justify-content: center;
  }
`;

const UserMenuItem = styled(Div)`
  display: flex;
  align-items: center;
  font-size: 17px;
  color: #222222;
  user-select: none;
  font-weight: normal;
  padding: 12px 10px;
  :hover {
    background-color: #e2e6e8;
    cursor: pointer;
    opacity: 0.9;
  }
`;

const Avatar = styled.img`
  border-radius: 100%;
  height: 35px;
  width: 35px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-right: -3px;
    height: 23px;
    width: 23px;
  }
`;

const Placeholder = styled(Div)`
  height: 35px;
  width: 35px;
  background-color: #fe8224;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border-radius: 100%;
`;

export default UserMenu;
