import styled, { css } from "styled-components";
import { Icon, GridRow, Select } from "notes";
import {
  DISAPPEARING_ANIMATION_LENGTH_SECONDS,
  FADING_ANIMATION_LENGTH_SECONDS,
  messageIsDone,
  MESSAGE_STATUS_LABEL,
  ShoutoutRequest,
  SORT_OPTIONS,
  TABS,
  useMessagesContext,
} from "./MessagesContextProvider";
import { LoaderInline } from "notes";
import emtpyMessageSvg from "../../assets/empty-message-list.svg";
import React, { useEffect, useRef, useState } from "react";
import {
  Div,
  paddingLeftCss,
  paddingRightCss,
  useVideoRecordContext,
} from "@max/common-ui";
import { DiscardRercordingModal } from "@max/common-ui";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { getResponseTimeWarningMessage } from "./common";
import ldb from "ldb";

const MessageList = () => {
  const {
    selectedTab,
    messages,
    isLoadingMessages,
    sortCompletedMessagesBy,
    selectedMessage,
    setCompletedMessageBy,
  } = useMessagesContext();

  useEffect(() => {
    (async () => {
      try {
        const aFileParts = ['<a id="a"><b id="b">hey!</b></a>'];
        const blob = new Blob(aFileParts, { type: "text/html" });
        await ldb.collection("blobs").add(
          {
            data: blob,
          },
          "testing-indexeddb"
        );
      } catch (e) {
        alert(
          "You may be using private mode. If so, you will not be able to upload ShoutOuts you record. Please use Safari in standard mode and try again."
        );
      }
    })();
  }, []);

  const emptyListUI = (
    <Div centered dflexColumn ml_n24>
      <EmptyMessageIndicator centered mt_140>
        <img src={emtpyMessageSvg} />
      </EmptyMessageIndicator>
      <EmptyMessageIndicatorText fs_12 w_184 textAlignCenter mt_24>
        {selectedTab === TABS.COMPLETED
          ? "You have not completed any video messages yet…"
          : "You’ve completed all video messages in your queue…"}
      </EmptyMessageIndicatorText>
    </Div>
  );

  const sortMessagesUI = (
    <SortSelectWrapper>
      <SortSelect
        icon={
          <Label yCenter>
            <Icon form name="Sort" /> Sort:
          </Label>
        }
        options={SORT_OPTIONS}
        selected={sortCompletedMessagesBy}
        onChange={(s) => setCompletedMessageBy(s)}
      />
    </SortSelectWrapper>
  );

  const headerUI = <>{selectedTab === TABS.COMPLETED && sortMessagesUI}</>;

  return (
    <Div h100>
      {isLoadingMessages ? (
        <Div w100 centered mt_50>
          <LoaderInline />
        </Div>
      ) : (
        <MessageListWrapper>
          {headerUI}
          <MessagesWrapper mt_12 pb_200>
            {messages.length === 0 && emptyListUI}
            {messages.map((message) => {
              return (
                <Div key={message.id}>
                  <Message message={message} />
                </Div>
              );
            })}
          </MessagesWrapper>
        </MessageListWrapper>
      )}
    </Div>
  );
};

export const ERROR_COUNT_THRESHOLD = 3;

type MessageProps = {
  message: ShoutoutRequest;
};
const Message: React.FC<MessageProps> = ({ message }) => {
  const {
    selectedTab,
    selectedMessage,
    selectMessage,
    fileUploadProgress,
    uploadFile,
  } = useMessagesContext();
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);
  const { userIsRecordingVideo } = useVideoRecordContext();
  const {
    public: { status },
    private: { error },
  } = message;
  const ref = useRef(null);

  const messageId = message.id;
  const [isDisappearing, setIsDisappearing] = useState(false);
  const [timeoutId, setTimeoutId] = useState(null);
  const uploadProgress =
    selectedTab === TABS.QUEUE && fileUploadProgress[messageId];
  const isProcessing =
    status === RequestStatus.processing ||
    uploadProgress?.status === MESSAGE_STATUS_LABEL.PROCESSING;
  const progress = uploadProgress ? uploadProgress.progress : 0;
  const isFading = [
    MESSAGE_STATUS_LABEL.completed,
    MESSAGE_STATUS_LABEL.rejected,
  ].includes(uploadProgress?.status)
    ? true
    : false;
  const isSelected = messageId === selectedMessage?.id;
  const isDisabled = isProcessing || isSelected || isFading;
  const userShouldDiscardRecording =
    status === RequestStatus.failed && error?.count >= ERROR_COUNT_THRESHOLD;
  const responseTimeWarningMessage = getResponseTimeWarningMessage(
    message.timeToLive
  );

  useEffect(() => {
    if (isSelected) ref.current.focus();
  }, [isSelected]);

  useEffect(() => {
    const isUploadingFile = fileUploadProgress[messageId];
    if (
      !messageIsDone(message) &&
      !userShouldDiscardRecording &&
      !isUploadingFile
    )
      uploadFile(messageId);
  }, [status, error?.count]);

  useEffect(() => {
    if (isFading) {
      const tid = setTimeout(() => {
        setIsDisappearing(true);
      }, FADING_ANIMATION_LENGTH_SECONDS * 1000);
      setTimeoutId(tid);
    }
    return () => {
      clearTimeout(timeoutId);
    };
  }, [isFading]);

  const messageQueueStatusUI = () => {
    if ((isProcessing || uploadProgress) && !userShouldDiscardRecording)
      return (
        <FileUploadStatus>
          {uploadProgress?.status || MESSAGE_STATUS_LABEL.PROCESSING}
        </FileUploadStatus>
      );
    else if (userShouldDiscardRecording)
      return <FileUploadStatus>{MESSAGE_STATUS_LABEL.FAILED}</FileUploadStatus>;
    else if (responseTimeWarningMessage)
      return (
        <Div dflex alignCenter>
          <Div mr_7 fs_14 bold c_yellow40>
            {responseTimeWarningMessage}
          </Div>
          <WarningIcon mt_4>
            <Icon form name="Error" />
          </WarningIcon>
        </Div>
      );
    else if (status === RequestStatus.received)
      return (
        <Div alignCenter>
          <NewIndicator />
        </Div>
      );
  };

  const messageDoneStatusUI = () => {
    if (status === RequestStatus.completed)
      return (
        <CompletedIndicator alignCenter>
          <Icon badge name="FacebookVerified" />
        </CompletedIndicator>
      );
    if (status === RequestStatus.rejected)
      return (
        <ExpiredIndicator alignCenter fs_12>
          <Div mr_10>Rejected</Div>
          <Icon form name="RemoveCircle" />
        </ExpiredIndicator>
      );
    if (status === RequestStatus.expired)
      return (
        <ExpiredIndicator alignCenter fs_12>
          <Div mr_10>Expired</Div>
          <Icon form name="Clock" />
        </ExpiredIndicator>
      );
  };

  return (
    <>
      <DiscardRercordingModal
        setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
        isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
        onConfirm={() => {
          selectMessage(message);
        }}
      />
      <MessageContainer
        tabIndex={0}
        ref={ref}
        isProcessing={isProcessing}
        isFading={isFading}
        isDisappearing={selectedTab === TABS.QUEUE && isDisappearing}
        positionRelative
        onClick={() => {
          if (isDisabled) return;
          if (userIsRecordingVideo) setIsDiscardRecordingModalOpen(true);
          else selectMessage(message);
        }}
      >
        <Progress
          progress={isProcessing ? 100 : progress}
          progressLabel={uploadProgress?.status}
        />
        {userShouldDiscardRecording && <ErrorIndicator />}
        <MessageWrapper isSelected={isSelected}>
          <MessageContent positionAbsolute spaceBetween>
            <RequestedByText ml_12>
              {message.private.form.requestedBy}
            </RequestedByText>
            {!messageIsDone(message) && messageQueueStatusUI()}
            {messageIsDone(message) && messageDoneStatusUI()}
          </MessageContent>
          {!isDisabled && (
            <Div centered forMobile>
              <ChevronRight positionAbsolute>
                <Icon height="16px" name="Next" />
              </ChevronRight>
            </Div>
          )}
        </MessageWrapper>
      </MessageContainer>
    </>
  );
};

const WarningIcon = styled(Div)`
  svg {
    path {
      fill: ${(props) => props.theme.colors.yellow40};
    }
  }
`;

const CompletedIndicator = styled(Div)`
  svg {
    path {
      fill: ${(props) => props.theme.colors.green20};
    }
  }
`;

const ExpiredIndicator = styled(Div)`
  color: ${(props) => props.theme.colors.gray40};
  font-weight: 400;
  font-style: italic;
  svg {
    path {
      fill: ${(props) => props.theme.colors.gray40};
    }
  }
`;

const NewIndicator = styled(Div)`
  background: ${(props) => props.theme.colors.green10};
  height: 12px;
  width: 12px;
  border-radius: 100%;
`;

const ChevronRight = styled(Div)`
  right: -3px;
  display: grid;
  place-items: center;
`;

const SortSelectWrapper = styled(Div)`
  margin-right: 19px;
  @media all and ${(props) => props.theme.media.mobile} {
    width: 100%;
  }
`;

const MessageContent = styled(Div)`
  width: calc(100% - 30px);
`;

const MessageContainer = styled(Div)`
  outline: none;
  ${(props) =>
    props.isProcessing &&
    css`
      pointer-events: none;
    `}
  height: 42px;
  ${(props) =>
    props.isFading &&
    css`
      opacity: 0;
      transition: opacity ${FADING_ANIMATION_LENGTH_SECONDS}s ease-out;
    `}
  ${(props) =>
    props.isDisappearing &&
    css`
      height: 0px;
      transition: height ${DISAPPEARING_ANIMATION_LENGTH_SECONDS}s ease-out;
    `}
`;

const FileUploadStatus = styled(Div)`
  font-size: 11px;
  font-style: italic;
  display: flex;
  align-items: center;
`;

const Progress = styled(Div)`
  position: absolute;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: ${(props) => props.theme.colors.blue30};
  border-radius: 2px 0 0 2px;
  width: ${(props) => props.progress || 0}%;
  ${(props) =>
    props.progress === 100 &&
    css`
      background-color: ${props.progressLabel === MESSAGE_STATUS_LABEL.rejected
        ? props.theme.colors.red25
        : props.theme.colors.green20};
    `}
  :hover {
    cursor: pointer;
  }
  @media all and ${(props) => props.theme.media.mobile} {
    border-radius: 2px;
  }
`;

const ErrorIndicator = styled(Progress)`
  width: 100%;
  background: ${(props) => props.theme.colors.red25};
`;

const EmptyMessageIndicator = styled(Div)`
  flex-direction: column;
  opacity: 0.24;
`;

const EmptyMessageIndicatorText = styled(Div)`
  font-style: italic;
  color: #a6aeb2;
`;

const MessageWrapper = styled(Div)`
  font-size: 17px;
  height: 100%;
  width: 100%;
  color: white;
  font-weight: 600;
  border-bottom: solid 1px ${(props) => props.theme.colors.purple50};
  user-select: none;
  ${(props) =>
    props.isSelected &&
    css`
      background-color: ${(props) => props.theme.colors.blue20};
    `}
  display: flex;
  border-radius: 3px;
  align-items: center;
  border-radius: 2px 0 0 2px;

  :hover {
    ${(props) =>
      !props.isSelected &&
      css`
        background-color: ${(props) => props.theme.colors.purple50};
        opacity: 0.8;
      `};
    cursor: pointer;
  }
`;

const SortSelect = styled(Select)`
  cursor: default;
  padding-left: 80px;
`;

const Label = styled(GridRow)`
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  svg {
    margin-right: 10px;
  }
`;

const MessageListWrapper = styled(Div)`
  padding-top: 20px;
  ${paddingLeftCss};
  font-size: 14px;
  @media all and ${(props) => props.theme.media.mobile} {
    padding-top: 14px;
    ${paddingRightCss}
  }
`;

const MessagesWrapper = styled(Div)`
  border-top: solid 1px ${(props) => props.theme.colors.purple50};
`;

const RequestedByText = styled(Div)`
  overflow: hidden;
  width: calc(100% - 130px);
  word-wrap: break-word;
  overflow-wrap: break-word;
  display: -webkit-box;
  -webkit-line-clamp: 1;
  -webkit-box-orient: vertical;
  -webkit-hyphens: auto;
  -moz-hyphens: auto;
  hyphens: auto;
  padding-right: 15px;
`;

export default MessageList;
