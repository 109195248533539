var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useField, useFormikContext } from "formik";
import { Checkbox, CountryPhone, DatePicker as DatePickerBase, ErrorMessage as ErrorMessageBase, GridColumn, Input as InputBase, Radio, Select, TextArea, TextAreaCounter, TextEditor, } from "notes";
import React from "react";
import styled, { css } from "styled-components";
import { useDebounce } from "../../hooks";
import { Div } from "../Div";
import ErrorMessage from "./ErrorMessage";
var LeftField = styled(InputBase)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border-radius: 4px 0 0 4px;\n  border-right: none;\n"], ["\n  border-radius: 4px 0 0 4px;\n  border-right: none;\n"])));
var RightField = styled(InputBase)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  border-radius: 0 4px 4px 0;\n"], ["\n  border-radius: 0 4px 4px 0;\n"])));
var InputWrapper = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  max-width: ", ";\n"], ["\n  max-width: ", ";\n"])), function (props) { return props.width; });
var MessageForInputWrapper = styled(Div)(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: flex;\n  margin-top: 10px;\n  user-select: none;\n  font-weight: 400;\n  ", "\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n  @media all and ", " {\n    border: solid 1px ", ";\n    border-radius: 4px;\n    height: 40px;\n    padding: 0 13px;\n    display: flex;\n    align-items: center;\n    :hover {\n      border: solid 1px ", ";\n      background: ", ";\n    }\n  }\n"], ["\n  display: flex;\n  margin-top: 10px;\n  user-select: none;\n  font-weight: 400;\n  ", "\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n  @media all and ", " {\n    border: solid 1px ", ";\n    border-radius: 4px;\n    height: 40px;\n    padding: 0 13px;\n    display: flex;\n    align-items: center;\n    :hover {\n      border: solid 1px ", ";\n      background: ", ";\n    }\n  }\n"])), function (props) {
    return props.isSelected && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n      font-weight: 500;\n    "], ["\n      font-weight: 500;\n    "])));
}, function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.colors.border; }, function (props) { return props.theme.colors.linkDefault; }, function (props) { return props.theme.colors.actionLight; });
var RadioInputWrapper = styled(Div)(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  user-select: none;\n  font-weight: 400;\n  margin-top: 18px;\n  ", "\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n  @media all and ", " {\n    border: solid 1px ", ";\n    border-radius: 4px;\n    padding: 10px 13px;\n    margin-top: 10px;\n    display: flex;\n    :hover {\n      border: solid 1px ", ";\n      background: ", ";\n    }\n  }\n"], ["\n  user-select: none;\n  font-weight: 400;\n  margin-top: 18px;\n  ", "\n  :hover {\n    cursor: pointer;\n    opacity: 0.8;\n  }\n  @media all and ", " {\n    border: solid 1px ", ";\n    border-radius: 4px;\n    padding: 10px 13px;\n    margin-top: 10px;\n    display: flex;\n    :hover {\n      border: solid 1px ", ";\n      background: ", ";\n    }\n  }\n"])), function (props) {
    return props.isSelected && css(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n      font-weight: 500;\n    "], ["\n      font-weight: 500;\n    "])));
}, function (props) { return props.theme.media.mobile; }, function (props) { return props.theme.colors.border; }, function (props) { return props.theme.colors.linkDefault; }, function (props) { return props.theme.colors.actionLight; });
var errorMessageUI = (React.createElement(Div, { c_inputErrorBorder: true }, "Please review the highlighted errors before attempting your submission again."));
export var ErrorWarningMessageContent = styled(ErrorMessageBase)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  width: 100%;\n  margin: 0;\n"], ["\n  width: 100%;\n  margin: 0;\n"])));
export var ErrorMessageWarning = function (_a) {
    var _b = _a.className, className = _b === void 0 ? "" : _b;
    var _c = useFormikContext(), submitCount = _c.submitCount, touched = _c.touched, errors = _c.errors;
    var formIsDisplayingErrors = function (touched, errors) {
        var res = false;
        var fields = Object.keys(touched);
        for (var i = 0; i < fields.length; i++)
            if (errors[fields[i]])
                res = true;
        return res;
    };
    return (React.createElement(Div, { className: className }, submitCount > 0 && formIsDisplayingErrors(touched, errors) && (React.createElement(Div, { centered: true },
        React.createElement(ErrorWarningMessageContent, { content: errorMessageUI })))));
};
export var MessageForInput = function (_a) {
    var name = _a.name, options = _a.options, _b = _a.onSelect, onSelect = _b === void 0 ? null : _b;
    var _c = useField(name), field = _c[0], setValue = _c[2].setValue;
    return (React.createElement(Div, null, options.map(function (opt, index) {
        var isSelected = field.value === opt.value;
        return (React.createElement(MessageForInputWrapper, { isSelected: isSelected, key: index, onClick: function () {
                setValue(opt.value);
                if (onSelect)
                    onSelect(opt.value);
            } },
            React.createElement(Radio, { onChange: null, checked: isSelected }),
            React.createElement(Div, null, opt.label)));
    })));
};
export var useFormikField = function (name) {
    var _a = useField(name), field = _a[0], meta = _a[1], helpers = _a[2];
    var setValue = helpers.setValue, setTouched = helpers.setTouched, setError = helpers.setError;
    var error = meta.error, touched = meta.touched;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var hasError = shouldDisplayError && touched && error && error !== "";
    return {
        value: field.value,
        setValue: setValue,
        setTouched: setTouched,
        setError: setError,
        hasError: hasError,
        errorMsg: error,
    };
};
var FieldWrapper = styled(Div)(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  :focus {\n    outline: none;\n  }\n"], ["\n  :focus {\n    outline: none;\n  }\n"])));
export var FormikField = function (_a) {
    var name = _a.name, children = _a.children, _b = _a.className, className = _b === void 0 ? "" : _b;
    var _c = useFormikField(name), hasError = _c.hasError, errorMsg = _c.errorMsg;
    return (React.createElement(FieldWrapper, { w100: true, name: name, tabIndex: "0", className: className },
        children,
        hasError && React.createElement(ErrorMessage, { msg: errorMsg })));
};
export var RadioInput = function (_a) {
    var name = _a.name, options = _a.options, _b = _a.onSelect, onSelect = _b === void 0 ? null : _b;
    var _c = useField(name), field = _c[0], setValue = _c[2].setValue;
    return (React.createElement(Div, null, options.map(function (opt, index) {
        var label = opt.label, description = opt.description, value = opt.value;
        var isSelected = field.value === value;
        return (React.createElement(RadioInputWrapper, { isSelected: isSelected, key: index, onClick: function () {
                setValue(value);
                if (onSelect)
                    onSelect(value);
            } },
            React.createElement(Div, { dflex: true },
                React.createElement(Div, { h_18: true, alignCenter: true, mt_4: true },
                    React.createElement(Radio, { onChange: null, checked: isSelected })),
                React.createElement(Div, null,
                    React.createElement(Div, null, label),
                    description && (React.createElement(Div, { fs_11: true, c_text: true, italic: true, lite: true }, description))))));
    })));
};
export var CheckboxMultiselect = function (_a) {
    var name = _a.name, options = _a.options;
    var _b = useField(name), field = _b[0], _c = _b[1], error = _c.error, touched = _c.touched, setValue = _b[2].setValue;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    var handleClick = function (checked, label) {
        !checked
            ? setValue(__spreadArray([], field.value, true).filter(function (v) { return v !== label; }))
            : setValue(__spreadArray(__spreadArray([], field.value, true), [label], false));
    };
    return (React.createElement(Div, null,
        options.map(function (opt, index) {
            var label = opt.label, value = opt.value;
            var isSelected = field.value.includes(value);
            return (React.createElement(Div, { dflex: true, mb_18: true, isSelected: isSelected, key: index, name: name, tabIndex: "0", as: "label" },
                React.createElement(Div, { dflex: true, clickable: true },
                    React.createElement(Checkbox, { onChange: function (checked) { return handleClick(checked, value); }, checked: isSelected }),
                    label)));
        }),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
var useDisplayErrorMessage = function (value) {
    var submitCount = useFormikContext().submitCount;
    if ((value === null || value === "") && submitCount === 0)
        return false;
    return true;
};
export var NameInput = function () {
    var _a, _b, _c, _d;
    var firstNameField = useField("firstName");
    var lastNameField = useField("lastName");
    var shouldDisplayError1 = useDisplayErrorMessage(firstNameField[0].value);
    var shouldDisplayError2 = useDisplayErrorMessage(lastNameField[0].value);
    var fieldCotainsError = function (field) {
        return field[1].touched && field[1].error && field[1].error !== "";
    };
    var displayError = fieldCotainsError(firstNameField) || fieldCotainsError(lastNameField);
    var error = ((_a = firstNameField[1]) === null || _a === void 0 ? void 0 : _a.error) || ((_b = lastNameField[1]) === null || _b === void 0 ? void 0 : _b.error);
    return (React.createElement(Div, { w100: true },
        React.createElement(Div, { dflex: true },
            React.createElement(LeftField, { name: "firstName", error: shouldDisplayError1 &&
                    displayError &&
                    fieldCotainsError(firstNameField), value: ((_c = firstNameField[0]) === null || _c === void 0 ? void 0 : _c.value) || "", placeholder: "First or given name...", onBlur: function () { return firstNameField[2].setTouched(true); }, onChange: function (v) { return firstNameField[2].setValue(v); } }),
            React.createElement(RightField, { name: "lastName", error: displayError &&
                    shouldDisplayError2 &&
                    fieldCotainsError(lastNameField), value: ((_d = lastNameField[0]) === null || _d === void 0 ? void 0 : _d.value) || "", placeholder: "Last or family name...", onBlur: function () { return lastNameField[2].setTouched(true); }, onChange: function (v) { return lastNameField[2].setValue(v); } })),
        shouldDisplayError1 && shouldDisplayError2 && displayError && (React.createElement(ErrorMessage, { msg: error }))));
};
export var DatePicker = function (_a) {
    var name = _a.name, _b = _a.width, width = _b === void 0 ? "392px" : _b, _c = _a.placeholder, placeholder = _c === void 0 ? "Pick a date..." : _c, _d = _a.InternalField, InternalField = _d === void 0 ? undefined : _d;
    var _e = useField(name), field = _e[0], _f = _e[1], error = _f.error, touched = _f.touched, setValue = _e[2].setValue;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    return (React.createElement(Div, null,
        React.createElement(InputWrapper, { width: width, name: name, tabIndex: "0" },
            React.createElement(GridColumn, null,
                React.createElement(DatePickerBase, { InternalField: InternalField, error: displayError, selected: field === null || field === void 0 ? void 0 : field.value, onSelect: function (v) { return setValue(v); }, placeholder: placeholder }))),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
export var Input = function (_a) {
    var name = _a.name, placeholder = _a.placeholder, _b = _a.leftIcon, leftIcon = _b === void 0 ? null : _b, _c = _a.width, width = _c === void 0 ? "392px" : _c, _d = _a.type, type = _d === void 0 ? "text" : _d, _e = _a.Component, Component = _e === void 0 ? null : _e, _f = _a.disabled, disabled = _f === void 0 ? false : _f;
    var _g = useField(name), field = _g[0], _h = _g[1], error = _h.error, touched = _h.touched, _j = _g[2], setValue = _j.setValue, setTouched = _j.setTouched;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    var _k = useDebounce(field === null || field === void 0 ? void 0 : field.value, setValue), _value = _k._value, set_Value = _k.set_Value;
    var InputComponent = Component || InputBase;
    return (React.createElement(Div, null,
        React.createElement(InputWrapper, { width: width, name: name, tabIndex: "0", style: { position: "relative", zIndex: 0 } },
            React.createElement(InputComponent, { onWheel: function (e) { return e.target.blur(); }, disabled: disabled, type: type, name: name, leftIcon: leftIcon, error: displayError, value: _value || "", placeholder: placeholder, onBlur: function () { return setTouched(true); }, onChange: function (v) {
                    set_Value(v);
                } })),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
export var CheckboxInputDescription = styled(Div)(templateObject_10 || (templateObject_10 = __makeTemplateObject([""], [""])));
export var CheckboxInput = function (_a) {
    var name = _a.name, description = _a.description, _b = _a.className, className = _b === void 0 ? "" : _b;
    var _c = useField(name), field = _c[0], _d = _c[1], error = _d.error, touched = _d.touched, setValue = _c[2].setValue;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    var isSelected = field.value;
    return (React.createElement(Div, { className: className },
        React.createElement(InputWrapper, { dflex: true, name: name, tabIndex: "0" },
            React.createElement(Div, { dflex: true, clickable: true, onClick: function () { return setValue(!isSelected); } },
                React.createElement(Checkbox, { checked: isSelected }),
                React.createElement(CheckboxInputDescription, { mt_1: true }, description))),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
export var SelectInput = function (_a) {
    var name = _a.name, options = _a.options, _b = _a.placeholder, placeholder = _b === void 0 ? "" : _b, _c = _a.width, width = _c === void 0 ? "392px" : _c, props = __rest(_a, ["name", "options", "placeholder", "width"]);
    var _d = useField(name), field = _d[0], _e = _d[1], error = _e.error, touched = _e.touched, setValue = _d[2].setValue;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    return (React.createElement(Div, null,
        React.createElement(InputWrapper, { width: width, name: name, tabIndex: "0" },
            React.createElement(Select, __assign({ error: displayError, placeholder: placeholder, options: options, selected: options.find(function (o) { return o.id == field.value; }) || "", onChange: function (o) {
                    setValue(o.id);
                } }, props))),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
export var TextareaCounterInput = function (_a) {
    var name = _a.name, placeholder = _a.placeholder, _b = _a.charLimit, charLimit = _b === void 0 ? "128" : _b;
    var _c = useField(name), field = _c[0], _d = _c[1], error = _d.error, touched = _d.touched, _e = _c[2], setValue = _e.setValue, setTouched = _e.setTouched;
    var shouldDisplayError = useDisplayErrorMessage(field.value);
    var displayError = shouldDisplayError && touched && error && error !== "";
    return (React.createElement(Div, { name: name, tabIndex: "0" },
        React.createElement(TextAreaCounter, { name: name, value: field.value || "", error: displayError, placeholder: placeholder, onBlur: function () { return setTouched(true); }, onChange: function (v) { return setValue(v); }, charLimit: charLimit }),
        displayError && React.createElement(ErrorMessage, { msg: error })));
};
export var TextareaInput = function (_a) {
    var name = _a.name, placeholder = _a.placeholder;
    var _b = useFormikField(name), value = _b.value, setValue = _b.setValue, hasError = _b.hasError, setTouched = _b.setTouched;
    return (React.createElement(FormikField, __assign({}, { name: name }),
        React.createElement(TextArea, { name: name, value: value || "", error: hasError, placeholder: placeholder, onBlur: function () { return setTouched(true); }, onChange: function (e) { return setValue(e.target.value); } })));
};
export var TextEditorInput = function (_a) {
    var name = _a.name, props = __rest(_a, ["name"]);
    var _b = useFormikField(name), value = _b.value, setValue = _b.setValue, hasError = _b.hasError;
    return (React.createElement(FormikField, __assign({}, { name: name }),
        React.createElement(TextEditor, __assign({ value: value, onChange: function (val) { return setValue(val); }, error: hasError }, props))));
};
export var PhoneInput = function (_a) {
    var name = _a.name, onValidate = _a.onValidate;
    var _b = useFormikField(name), value = _b.value, setValue = _b.setValue;
    return (React.createElement(FormikField, __assign({}, { name: name }),
        React.createElement(CountryPhone, { value: value, onValidate: onValidate, onChange: function (v) {
                setValue(v);
            } })));
};
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10;
