import { Div } from "@max/common-ui";
import { DiscardRercordingModal } from "@max/common-ui";
import { Videojs } from "@max/common-ui";
import { useState } from "react";
import styled from "styled-components";
import { Icon, ButtonSecondary } from "notes";
import { VideoRecorderWrapper } from "@max/common-ui";

const VideoPlaceholderContainer = styled(Div)`
  border: solid 1px ${(props) => props.theme.colors.gray20};
  border-radius: 4px;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 500px;
  @media all and ${(props) => props.theme.media.mobile} {
    height: auto;
    border: none;
    display: block;
  }
`;

const DeleteRecordingButton = styled(Div)`
  font-weight: 500;
  font-size: 17px;
  color: ${(props) => props.theme.colors.red20};
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
    text-decoration: underline;
  }
`;

const Button = styled(ButtonSecondary)`
  svg {
    path {
      fill: ${(props) => props.theme.colors.action};
    }
  }
  :hover,
  :active,
  :focus {
    svg {
      path {
        fill: ${(props) => props.theme.colors.white};
      }
    }
  }
`;

const Wrapper = styled(Div)`
  @media all and ${(props) => props.theme.media.mobile} {
    margin: 40px 0;
  }
`;

const PlaceholderText = styled(Div)`
  text-align: center;
  color: ${(props) => props.theme.colors.text};
  font-size: 17px;
  font-weight: bold;
  margin-top: -100px;
  @media all and ${(props) => props.theme.media.mobile} {
    text-align: left;
    margin-top: -10px;
  }
`;

const VideoDescription = styled(Div)`
  width: 288px;
  font-weight: 300;
  font-size: 12px;
  @media all and ${(props) => props.theme.media.mobile} {
    margin-top: 5px;
    width: 100%;
    font-size: 17px;
    margin-bottom: 23px;
  }
`;

const VideoField = ({
  recordingBlob,
  setRecordingBlob,
  setIsVideoRecorderOpen,
}) => {
  const [
    isDiscardRecordingModalOpen,
    setIsDiscardRecordingModalOpen,
  ] = useState(false);

  const videoUI = (
    <Div>
      <Div centered>
        <VideoRecorderWrapper>
          <Videojs
            withObjectFitCover={true}
            width="100%"
            height="100%"
            blob={recordingBlob.blob}
          />
        </VideoRecorderWrapper>
        <DiscardRercordingModal
          setIsDiscardRecordingModalOpen={setIsDiscardRecordingModalOpen}
          isDiscardRecordingModalOpen={isDiscardRecordingModalOpen}
          onConfirm={() =>
            setRecordingBlob({ blob: null, isInitialValue: false })
          }
        />
      </Div>
      <Div centered mt_28>
        <DeleteRecordingButton
          onClick={() => setIsDiscardRecordingModalOpen(true)}
        >
          Delete Recording
        </DeleteRecordingButton>
      </Div>
    </Div>
  );

  const recordButtonUI = (
    <>
      <Div centered mt_25 forDesktop>
        <Button onClick={() => setIsVideoRecorderOpen(true)}>
          <Icon name="Video" />
          <Div ml_5>Record</Div>
        </Button>
      </Div>
      <Div forMobile>
        <Div c_action clickable onClick={() => setIsVideoRecorderOpen(true)}>
          Record
        </Div>
      </Div>
    </>
  );

  const videoPlaceholderUI = (
    <VideoPlaceholderContainer mcontainer>
      <PlaceholderText>
        <Div>Promotional Video (optional)</Div>
        <VideoDescription>
          You may add an intro video to tell fans what to expect from a Shout
          Out from you.
        </VideoDescription>
        {recordButtonUI}
      </PlaceholderText>
    </VideoPlaceholderContainer>
  );
  return <Wrapper>{recordingBlob.blob ? videoUI : videoPlaceholderUI}</Wrapper>;
};

export default VideoField;
