import styled from "styled-components";
import VideoMessageRecorder from "./VideoMessageRecorder";
import MessageDetail from "./MessageDetail";
import { useMessagesContext } from "../MessagesContextProvider";
import { Div, scrollBarDarkCss } from "@max/common-ui";

const VideoMessage = () => {
  const { selectedMessage } = useMessagesContext();
  return (
    <Container h100 w100>
      <div>
        <Wrapper>
          {selectedMessage && (
            <>
              <MessageDetail />
              <VideoMessageRecorder />
            </>
          )}
        </Wrapper>
      </div>
    </Container>
  );
};

const Wrapper = styled(Div)`
  display: flex;
  justify-content: center;
  margin-right: 4%;
  margin-left: 4%;
  padding-bottom: 70px;
  padding-top: 5%;
  @media all and (min-width: 1600px) {
    padding-top: 60px;
  }
  @media all and (max-width: 1450px) {
    padding-top: 4vh;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    background-color: black;
    padding: 0;
    margin: 0;
    flex-direction: column-reverse;
    padding: 0;
    margin: 0;
  }
`;

const Container = styled(Div)`
  overflow-y: scroll;
  ${scrollBarDarkCss}
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    overflow: hidden;
  }
`;
export default VideoMessage;
