import styled from "styled-components";
import parse, { domToReact } from "html-react-parser";
import _ from "lodash";
import { Div } from "@max/common-ui";

const options = {
  replace: ({ name, children, attribs }) => {
    switch (name) {
      case "helpsection":
        return (
          <HelpSection {...attribs}>
            {domToReact(children, options)}
          </HelpSection>
        );
      case "helpsectiontitle":
        return (
          <HelpSectionTitle {...attribs}>
            {domToReact(children, options)}
          </HelpSectionTitle>
        );
      case "helpsectioncontent":
        return (
          <HelpSectionContent {...attribs}>
            {domToReact(children, options)}
          </HelpSectionContent>
        );
      case "a": {
        const href = _.get(attribs, "href", null);
        delete attribs.href;
        return href.toLowerCase().indexOf("http") === 0 ? (
          <AbsoluteLink href={href}>
            {domToReact(children, options)}
          </AbsoluteLink>
        ) : (
          <LinkStyle
            to={href ? href : "/"}
            {...attribs}
            style={{ display: "inline-block" }}
          >
            {domToReact(children, options)}
          </LinkStyle>
        );
      }
      case "strong":
      case "b": {
        return (
          <span style={{ fontWeight: 800 }}>
            {domToReact(children, options)}
          </span>
        );
      }
      case "link": {
        return (
          <LinkStyle {...attribs} style={{ display: "inline-block" }}>
            {domToReact(children, options)}
          </LinkStyle>
        );
      }
    }
  },
};
export default (html) => (html && html !== "" ? parse(html, options) : html);

export const HelpSection = styled.div``;

export const HelpSectionTitle = styled.span`
  display: block;
  margin-top: 24px;
  font-weight: 700;
  ${(props) => props.theme.fonts.default};
  font-size: 14px;
  line-height: 22px;
  color: ${(props) => props.theme.colors.black};
`;

export const HelpSectionContent = styled.div`
  ${(props) => props.theme.fonts.default};
  color: ${(props) => props.theme.colors.black};
  font-size: 17px;
  line-height: 27px;
`;

const LinkStyle = styled(Div)`
  color: ${(props) => props.theme.palette.purple.primary};
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.purple.darker};
  }
`;

const AbsoluteLink = styled.a`
  color: ${(props) => props.theme.palette.purple.primary};
  cursor: pointer;
  font-weight: 600;
  text-decoration: none;
  &:hover {
    color: ${(props) => props.theme.palette.purple.darker};
  }
`;
