import styled from "styled-components";
import { fstore } from "../../../firebase-tools";
import {
  messageIsCompleted,
  messageIsDone,
  TABS,
  useMessagesContext,
} from "../MessagesContextProvider";
import { quoteOpenIcon, quoteClosedIcon } from "../../common/icons";
import { useEffect, useRef, useState } from "react";
import ToClipboard from "./VideoMessageRecorder/ToClipboard";
import { useMediaQuery, useWindowInnerHeight } from "@max/common-ui";
import { RequestStatus } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { DateTime } from "luxon";
import { Div, scrollBarCss, useVideoRecordContext } from "@max/common-ui";
import { theme } from "theme";

const ToClipboardWrapper = styled(Div)`
  width: 310px;
`;

const ToClipboardContainer = styled(Div)`
  @media not all and ${(props) => props.theme.media.verticalDisplay} {
    display: none;
  }
`;

const Field = ({ label, value }) => {
  return (
    <>
      {value && (
        <Div mb_25>
          <Label>{label}</Label>
          <Value>{value}</Value>
        </Div>
      )}
    </>
  );
};

const getTimestampText = (label, date) => {
  const getDaysAgo = (d) => {
    const days = Math.round(DateTime.now().diff(d, "days").toObject().days);
    return d.hasSame(DateTime.local(), "day")
      ? "(Today)"
      : `(${days} Day${days > 1 ? "s" : ""} ago)`;
  };

  return date ? `${label}: ${date.toFormat("DD")} ${getDaysAgo(date)}` : null;
};

const MessageDetail = () => {
  const {
    selectedTab,
    selectedMessage: message,
    selectMessage,
  } = useMessagesContext();
  const wrapperRef = useRef(null);
  const [distanceTop, setDistanceTop] = useState(null);
  const windowInnerHeight = useWindowInnerHeight();
  const { userIsRecordingVideo } = useVideoRecordContext();
  const { fileUploadProgress } = useMessagesContext();
  const isMobile = useMediaQuery(theme.media.mobile);
  const isUploadingVideo = Object.keys(fileUploadProgress).includes(message.id);

  const {
    id: messageId,
    public: { status, download },
    private: { form, source: messageSource },
  } = message;
  const recordingUrl =
    status === RequestStatus.completed ? download?.url : null;
  if (status === RequestStatus.received)
    fstore
      .doc(`shoutout_requests/${message.id}`)
      .update({ status: RequestStatus.viewed });

  const setTop = () =>
    setDistanceTop(wrapperRef?.current?.getBoundingClientRect().top);

  useEffect(() => {
    setTop();
    window.onresize = setTop;
  }, []);

  const rejectRequest = () => {
    fstore.doc(`shoutout_requests/${messageId}`).update({
      status: RequestStatus.rejected,
    });
    if (isMobile) selectMessage(null);
  };

  const messageUI = (
    <Quote mt_n18 centered>
      <Div w100>
        <LeftQuote ml_n18 mb_10>
          {quoteOpenIcon}
        </LeftQuote>
        <Div w100 centered>
          {message.private.form.message}
        </Div>
        <RightQuote mt_17 mr_n18>
          {quoteClosedIcon}
        </RightQuote>
      </Div>
    </Quote>
  );

  const requestedAtText = getTimestampText(
    "Requested",
    message.public.createdAt
  );
  const sentAtText = getTimestampText("Delivered", message?.public?.sentAt);
  const updatedAtText = getTimestampText("Updated", message?.public?.updatedAt);
  return (
    <Wrapper innerHeight={windowInnerHeight} ref={wrapperRef} top={distanceTop}>
      {selectedTab === TABS.COMPLETED && messageIsCompleted(message) && (
        <ToClipboardContainer centered mb_30>
          <ToClipboardWrapper>
            <ToClipboard url={recordingUrl} />
          </ToClipboardWrapper>
        </ToClipboardContainer>
      )}
      <Fields>
        <Field value={form.requestedBy} label="Message requested by" />
        <Field value={form.messageFor} label="Message is for" />
        <Field value={form.relation} label="Relationship" />
        <Field value={form.starter} label="Conversation starter" />
        <DetailsFromFan w100>
          <Field value={form.message} label="Details from fan" />
        </DetailsFromFan>
      </Fields>
      {form.message && messageUI}
      <Footer>
        <Div>
          <Div>{messageSource.label} </Div>
          {requestedAtText && <LightText>{requestedAtText}</LightText>}
          {sentAtText ? (
            <LightText>{sentAtText}</LightText>
          ) : (
            <LightText>{updatedAtText}</LightText>
          )}
        </Div>
        {!isUploadingVideo && !userIsRecordingVideo && !messageIsDone(message) && (
          <DeclineRequestButton mt_20 onClick={rejectRequest}>
            Decline Request
          </DeclineRequestButton>
        )}
      </Footer>
    </Wrapper>
  );
};

const DetailsFromFan = styled(Div)`
  @media not all and ${(props) => props.theme.media.verticalDisplay} {
    display: none;
  }
`;

const LeftQuote = styled(Div)`
  svg {
    path {
      fill: #b3a6df;
    }
  }
`;

const RightQuote = styled(LeftQuote)`
  display: flex;
  text-align: right;
  flex-direction: row-reverse;
`;

const LightText = styled(Div)`
  font-weight: 300;
  margin-top: 6px;
`;

const Footer = styled(Div)`
  font-size: 11px;
  padding: 19px 24px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    padding-top: 0;
    padding-bottom: 80px;
  }
  font-style: italic;
  font-weight: 600;
  height: 100%;
  display: grid;
  grid-template-rows: 1fr auto;
`;

const DeclineRequestButton = styled(Div)`
  color: ${(props) => props.theme.colors.red20};
  font-weight: 500;
  font-size: 15px;
  font-style: normal;
  user-select: none;
  :hover {
    cursor: pointer;
    opacity: 0.8;
  }
`;

const Fields = styled(Div)`
  padding: 16px 24px;
`;

const Quote = styled(Div)`
  background-color: #f0edfb;
  padding: 35px 47px;
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    display: none;
  }
`;

const Label = styled(Div)`
  color: #a6aeb2;
  font-size: 15px;
`;

const Value = styled(Div)`
  font-size: 17px;
  font-weight: 600;
`;

const Wrapper = styled(Div)`
  display: flex;
  flex-direction: column;
  margin-right: 50px;
  width: 436px;
  border-radius: 4px;
  background-color: white;
  color: #222222;
  @media all and (max-width: 1580px) {
    margin-right: 2.5vw;
  }
  @media all and ${(props) => props.theme.media.verticalDisplay} {
    width: 100%;
    border-radius: 0;
    margin-right: 0;
    overflow-y: scroll;
    ${scrollBarCss}
    height: calc(${(props) => props.innerHeight}px - ${(props) => props.top}px);
  }
`;

export default MessageDetail;
