import React from "react";
import ChromeDefault from "../../../../assets/images/ChromeDefault.png";
import FirefoxDefault from "../../../../assets/images/FirefoxDefault.png";
import SafariDefault from "../../../../assets/images/SafariDefault.png";
import { ImageLogo } from "./windows";
var imagePath = "".concat(process.env.REACT_APP_ASSETS_PATH, "/troubleshoot/macos");
export var macos = [
    {
        key: "chrome",
        name: "Chrome",
        logo: React.createElement(ImageLogo, { src: ChromeDefault }),
        data: [
            {
                type: "text",
                content: "Allow Chrome access to Microphone and Camera",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/1.png"),
            },
            {
                type: "text",
                content: "Or, click lock icon and choose Site settings",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/2.png"),
            },
            {
                type: "text",
                content: "Under Permissions, Allow access to Camera and Microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/3.png"),
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/4.png"),
            },
            {
                type: "text",
                content: "Refresh website",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/5.png"),
            },
        ],
    },
    {
        key: "firefox",
        name: "Firefox",
        logo: React.createElement(ImageLogo, { src: FirefoxDefault }),
        data: [
            {
                type: "text",
                content: "Allow Firefox access to Microphone and Camera",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/1.png"),
            },
            {
                type: "text",
                content: "Or, click lock icon and then the arrow to the right",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/2.png"),
            },
            {
                type: "text",
                content: "Click More Information to access additional settings",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/3.png"),
            },
            {
                type: "text",
                content: "Click Permissions tab and scroll to bottom",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/4.png"),
            },
            {
                type: "text",
                content: "Allow Firefox access to use the Camera and Microphone",
            },
        ],
    },
    {
        key: "safari",
        name: "Safari",
        logo: React.createElement(ImageLogo, { src: SafariDefault }),
        data: [
            {
                type: "text",
                content: "Allow Safari access to Camera and Microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/safari/1.png"),
            },
            {
                type: "text",
                content: "Or, right-click the URL in the address bar and select Settings for This Website…",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/safari/2.png"),
            },
            {
                type: "text",
                content: "Allow access to Camera and Microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/safari/3.png"),
            },
        ],
    },
];
