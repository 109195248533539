var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React from "react";
import { useEffect, useRef, useState } from "react";
import { Icon } from "notes";
import styled from "styled-components";
import Meter from "./Meter";
import { Div } from "../../../Div";
var mediaRecorder;
var audioContext;
var stream;
var micNode;
var scriptNode;
var isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
export var isInlineRecordingSupported = !!window.MediaSource &&
    !!window.MediaRecorder &&
    !!navigator.mediaDevices &&
    !isSafari;
/* connectAudioNodes and disconnectAudioNodes prevent
state updates on unmounted component with setVolumeLevel */
var connectAudioNodes = function () {
    micNode.connect(scriptNode);
    scriptNode.connect(audioContext.destination);
};
var disconnectAudioNodes = function () {
    micNode.disconnect(scriptNode);
    scriptNode.disconnect(audioContext.destination);
};
var getMimeType = function () {
    var mimeType = "video/webm;codecs=vp9,opus";
    if (!MediaRecorder.isTypeSupported(mimeType)) {
        console.error("".concat(mimeType, " is not supported"));
        mimeType = "video/webm;codecs=vp8,opus";
        if (!MediaRecorder.isTypeSupported(mimeType)) {
            console.error("".concat(mimeType, " is not supported"));
            mimeType = "video/webm";
            if (!MediaRecorder.isTypeSupported(mimeType)) {
                console.error("".concat(mimeType, " is not supported"));
                mimeType = "video/mp4; codecs=avc1.4d002a";
                if (!MediaRecorder.isTypeSupported(mimeType)) {
                    console.error("".concat(mimeType, " is not supported"));
                    mimeType = "";
                }
            }
        }
    }
    return mimeType;
};
var useStopwatch = function () {
    var _a = useState(0), seconds = _a[0], setSeconds = _a[1];
    var _b = useState(null), intervalId = _b[0], setIntervalId = _b[1];
    useEffect(function () {
        return function () {
            clearInterval(intervalId);
        };
    }, []);
    var startStopwatch = function () {
        setSeconds(0);
        var iid = setInterval(function () {
            setSeconds(function (prev) { return prev + 1; });
        }, 1000);
        setIntervalId(iid);
    };
    var stopStopwatch = function () {
        if (intervalId)
            clearInterval(intervalId);
    };
    var format_mmss = function () {
        var m = Math.floor(seconds / 60);
        var s = seconds % 60;
        var pad0 = function (t) { return (t < 10 ? "0".concat(t) : t); };
        var mm = pad0(m);
        var ss = pad0(s);
        return "".concat(mm, ":").concat(ss);
    };
    return {
        startStopwatch: startStopwatch,
        stopStopwatch: stopStopwatch,
        seconds: seconds,
        stopwatch: format_mmss(),
    };
};
var getDeviceIdsByKind = function (devices, kind) {
    var dvsbyKind = devices === null || devices === void 0 ? void 0 : devices.filter(function (device) { return device.kind === kind; });
    return dvsbyKind === null || dvsbyKind === void 0 ? void 0 : dvsbyKind.map(function (inp) { return inp.deviceId; });
};
var useMediaDevices = function () {
    var _a = useState(null), devices = _a[0], setDevices = _a[1];
    var getDevices = function () { return __awaiter(void 0, void 0, void 0, function () {
        var dvs, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 3, , 4]);
                    return [4 /*yield*/, navigator.mediaDevices.getUserMedia({ audio: true, video: true })];
                case 1:
                    _a.sent();
                    return [4 /*yield*/, navigator.mediaDevices.enumerateDevices()];
                case 2:
                    dvs = _a.sent();
                    setDevices(dvs);
                    return [2 /*return*/, dvs];
                case 3:
                    err_1 = _a.sent();
                    setDevices(null);
                    throw err_1;
                case 4: return [2 /*return*/];
            }
        });
    }); };
    var getValidDeviceIds = function (idealAudioDeviceId, idealVideoDeviceId) {
        if (idealAudioDeviceId === void 0) { idealAudioDeviceId = null; }
        if (idealVideoDeviceId === void 0) { idealVideoDeviceId = null; }
        return __awaiter(void 0, void 0, void 0, function () {
            var dvs, audioDeviceIds, videoDeviceIds, defaultAudioDeviceId, defaultVideoDeviceId, selectedDeviceIds;
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0:
                        if (!!devices) return [3 /*break*/, 2];
                        return [4 /*yield*/, getDevices()];
                    case 1:
                        dvs = _a.sent();
                        navigator.mediaDevices.addEventListener("devicechange", getDevices);
                        return [3 /*break*/, 3];
                    case 2:
                        dvs = devices;
                        _a.label = 3;
                    case 3:
                        audioDeviceIds = getDeviceIdsByKind(dvs, "audioinput");
                        videoDeviceIds = getDeviceIdsByKind(dvs, "videoinput");
                        defaultAudioDeviceId = audioDeviceIds[0];
                        defaultVideoDeviceId = videoDeviceIds[0];
                        selectedDeviceIds = {
                            audioDeviceId: audioDeviceIds.includes(idealAudioDeviceId)
                                ? idealAudioDeviceId
                                : defaultAudioDeviceId,
                            videoDeviceId: videoDeviceIds.includes(idealVideoDeviceId)
                                ? idealVideoDeviceId
                                : defaultVideoDeviceId,
                        };
                        return [2 /*return*/, selectedDeviceIds];
                }
            });
        });
    };
    useEffect(function () {
        return function () {
            if (devices)
                navigator.mediaDevices.removeEventListener("devicechange", getDevices);
        };
    }, []);
    return {
        devices: devices,
        getValidDeviceIds: getValidDeviceIds,
    };
};
var COUNT_DOWN_SECONDS = 3;
export var VideoRecorderInput = function (_a) {
    var _b = _a.width, width = _b === void 0 ? "350px" : _b, _c = _a.height, height = _c === void 0 ? "620px" : _c, _d = _a.constraints, constraints = _d === void 0 ? {} : _d, _e = _a.initializeOnMount, initializeOnMount = _e === void 0 ? false : _e, _f = _a.withVolumeLevel, withVolumeLevel = _f === void 0 ? true : _f, _g = _a.onStartCountDown, onStartCountDown = _g === void 0 ? null : _g, _h = _a.onCancelCountDown, onCancelCountDown = _h === void 0 ? null : _h, _j = _a.onStartRecording, onStartRecording = _j === void 0 ? null : _j, _k = _a.onStopRecording, onStopRecording = _k === void 0 ? null : _k, _l = _a.onTroubleshoot, onTroubleshoot = _l === void 0 ? null : _l, _m = _a.onError, onError = _m === void 0 ? null : _m, _o = _a.onInit, onInit = _o === void 0 ? null : _o, _p = _a.onMounting, onMounting = _p === void 0 ? null : _p, _q = _a.selectedAudioDeviceId, selectedAudioDeviceId = _q === void 0 ? null : _q, _r = _a.selectedVideoDeviceId, selectedVideoDeviceId = _r === void 0 ? null : _r, setSelectedAudioDeviceId = _a.setSelectedAudioDeviceId, setSelectedVideoDeviceId = _a.setSelectedVideoDeviceId, _s = _a.setDevices, setDevices = _s === void 0 ? null : _s;
    var videoRef = useRef(null);
    var startRecordingTimeoutId = useRef(null);
    var countDownIntervalId = useRef(null);
    var videoInputRef = useRef(null);
    var isMounted = useRef(false);
    var _t = useState(false), isRecording = _t[0], setIsRecording = _t[1];
    var _u = useState(false), isCameraOpen = _u[0], setIsCameraOpen = _u[1];
    var _v = useState(false), missingCameraOrMic = _v[0], setMissingCameraOrMic = _v[1];
    var _w = useState(0), volumeLevel = _w[0], setVolumeLevel = _w[1];
    var _x = useState(0), countDown = _x[0], setCountDown = _x[1];
    var _y = useStopwatch(), seconds = _y.seconds, stopwatch = _y.stopwatch, startStopwatch = _y.startStopwatch, stopStopwatch = _y.stopStopwatch;
    var _z = useMediaDevices(), devices = _z.devices, getValidDeviceIds = _z.getValidDeviceIds;
    var _0 = useState(false), shouldTroubleshoot = _0[0], setShouldTroubleshoot = _0[1];
    var handleError = function (err) {
        console.log(err);
        setShouldTroubleshoot(true);
        clearTimers();
        if (onStopRecording)
            onStopRecording();
        if (onCancelCountDown)
            onCancelCountDown();
        setIsCameraOpen(false);
        setIsRecording(false);
        setMissingCameraOrMic(true);
        if (onError)
            onError("Missing camera or microphone");
    };
    var initMediaRecoder = function () { return __awaiter(void 0, void 0, void 0, function () {
        var setupAudio, _a, audioDeviceId, videoDeviceId, defaultVideoConstraints, preferredVideoConstraints, mediaConstraints, err_2, err_3;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    setupAudio = function () {
                        audioContext = new AudioContext();
                        scriptNode = audioContext.createScriptProcessor(2048, 1, 1);
                        scriptNode.onaudioprocess = function (event) {
                            var input = event.inputBuffer.getChannelData(0);
                            var i;
                            var sum = 0.0;
                            var clipcount = 0;
                            for (i = 0; i < input.length; ++i) {
                                sum += input[i] * input[i];
                                if (Math.abs(input[i]) > 0.99) {
                                    clipcount += 1;
                                }
                            }
                            var instant = Math.sqrt(sum / input.length);
                            setVolumeLevel(instant);
                        };
                        micNode = audioContext.createMediaStreamSource(stream);
                        connectAudioNodes();
                    };
                    _b.label = 1;
                case 1:
                    _b.trys.push([1, 8, , 9]);
                    return [4 /*yield*/, getValidDeviceIds(selectedAudioDeviceId, selectedVideoDeviceId)];
                case 2:
                    _a = _b.sent(), audioDeviceId = _a.audioDeviceId, videoDeviceId = _a.videoDeviceId;
                    defaultVideoConstraints = {
                        deviceId: videoDeviceId ? { exact: videoDeviceId } : undefined,
                        facingMode: "user",
                    };
                    preferredVideoConstraints = __assign(__assign({}, defaultVideoConstraints), { width: {
                            min: 640,
                            ideal: 1280,
                            max: 1920,
                        }, height: {
                            min: 480,
                            ideal: 720,
                            max: 1280,
                        }, frameRate: {
                            min: 15,
                        } });
                    mediaConstraints = __assign({ audio: {
                            deviceId: audioDeviceId ? { exact: audioDeviceId } : undefined,
                        } }, constraints);
                    _b.label = 3;
                case 3:
                    _b.trys.push([3, 5, , 7]);
                    return [4 /*yield*/, navigator.mediaDevices.getUserMedia(__assign(__assign({}, mediaConstraints), { video: preferredVideoConstraints }))];
                case 4:
                    stream = _b.sent();
                    return [3 /*break*/, 7];
                case 5:
                    err_2 = _b.sent();
                    return [4 /*yield*/, navigator.mediaDevices.getUserMedia(__assign(__assign({}, mediaConstraints), { video: defaultVideoConstraints }))];
                case 6:
                    stream = _b.sent();
                    return [3 /*break*/, 7];
                case 7:
                    setSelectedAudioDeviceId(audioDeviceId);
                    setSelectedVideoDeviceId(videoDeviceId);
                    if (videoRef && videoRef.current)
                        videoRef.current.srcObject = stream;
                    setupAudio();
                    setIsCameraOpen(true);
                    setMissingCameraOrMic(false);
                    return [3 /*break*/, 9];
                case 8:
                    err_3 = _b.sent();
                    handleError(err_3);
                    return [3 /*break*/, 9];
                case 9: return [2 /*return*/];
            }
        });
    }); };
    var clearTimers = function () {
        if (countDownIntervalId.current)
            clearInterval(countDownIntervalId.current);
        if (startRecordingTimeoutId.current)
            clearTimeout(startRecordingTimeoutId.current);
        setCountDown(0);
    };
    var stopMedia = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (isInlineRecordingSupported && stream)
                        stream.getTracks().forEach(function (track) {
                            track.stop();
                        });
                    if (!(audioContext && audioContext.state === "running")) return [3 /*break*/, 2];
                    return [4 /*yield*/, audioContext.close()];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        isMounted.current = true;
        if (initializeOnMount)
            init();
        if (onMounting)
            onMounting();
        return function () {
            clearTimers();
            stopMedia().then(function () {
                isMounted.current = false;
            });
        };
    }, []);
    useEffect(function () {
        if (seconds > 600)
            stopRecording();
    }, [seconds]);
    useEffect(function () {
        if (countDown < 1 && countDownIntervalId.current)
            clearInterval(countDownIntervalId.current);
    }, [countDown]);
    useEffect(function () {
        var reset = function () { return __awaiter(void 0, void 0, void 0, function () {
            return __generator(this, function (_a) {
                switch (_a.label) {
                    case 0: return [4 /*yield*/, stopMedia()];
                    case 1:
                        _a.sent();
                        return [4 /*yield*/, initMediaRecoder()];
                    case 2:
                        _a.sent();
                        return [2 /*return*/];
                }
            });
        }); };
        var verifySelectedDevices = function () {
            var audioDeviceIds = getDeviceIdsByKind(devices, "audioinput");
            var videoDeviceIds = getDeviceIdsByKind(devices, "videoinput");
            var disconnectedSelectedDevice = !(audioDeviceIds === null || audioDeviceIds === void 0 ? void 0 : audioDeviceIds.includes(selectedAudioDeviceId)) ||
                !(videoDeviceIds === null || videoDeviceIds === void 0 ? void 0 : videoDeviceIds.includes(selectedVideoDeviceId));
            if (isRecording && disconnectedSelectedDevice && onError)
                onError("Missing camera or microphone. Please discard recording and try again");
        };
        verifySelectedDevices();
        setDevices(devices);
        clearTimers();
        if (stream && !isRecording && isCameraOpen)
            reset();
    }, [devices, selectedAudioDeviceId, selectedVideoDeviceId]);
    var startRecording = function () {
        setCountDown(COUNT_DOWN_SECONDS);
        if (onStartCountDown)
            onStartCountDown();
        var iId = setInterval(function () {
            setCountDown(function (prev) { return prev - 1; });
        }, 1000);
        countDownIntervalId.current = iId;
        var sTId = setTimeout(function () {
            try {
                var mimeType_1 = getMimeType();
                var onEndRecording = function (event) { return __awaiter(void 0, void 0, void 0, function () {
                    var blob;
                    return __generator(this, function (_a) {
                        if (event.data && event.data.size > 0) {
                            blob = new Blob([event.data], { type: mimeType_1 });
                            onStopRecording(blob);
                        }
                        return [2 /*return*/];
                    });
                }); };
                var options = { mimeType: mimeType_1 };
                mediaRecorder = new MediaRecorder(stream, options);
                mediaRecorder.ondataavailable = onEndRecording;
                mediaRecorder.start();
                connectAudioNodes();
                startStopwatch();
                if (onStartRecording)
                    onStartRecording();
                setIsRecording(true);
                setMissingCameraOrMic(false);
            }
            catch (err) {
                handleError(err);
            }
        }, COUNT_DOWN_SECONDS * 1000);
        startRecordingTimeoutId.current = sTId;
    };
    var stopRecording = function () {
        stopStopwatch();
        disconnectAudioNodes();
        mediaRecorder.stop();
    };
    var cancelCountDown = function () {
        if (onCancelCountDown)
            onCancelCountDown();
        clearTimers();
    };
    var init = function () { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    if (!isInlineRecordingSupported) return [3 /*break*/, 2];
                    return [4 /*yield*/, initMediaRecoder()];
                case 1:
                    _a.sent();
                    if (onInit)
                        onInit();
                    return [3 /*break*/, 3];
                case 2:
                    videoInputRef.current.value = null;
                    videoInputRef.current.click();
                    _a.label = 3;
                case 3:
                    setIsRecording(false);
                    return [2 /*return*/];
            }
        });
    }); };
    var handleVideoSelected = function (e) {
        var files = e.target.files || e.dataTransfer.files;
        if (files.length === 0)
            return;
        var blob = files[0];
        e.target.value = null;
        onStopRecording(blob);
    };
    var videoInput = (React.createElement(VideoInput, { ref: videoInputRef, type: "file", accept: "video/*", capture: "user", onChange: handleVideoSelected }));
    var recordingAction = !isRecording ? (React.createElement(Button, { onClick: startRecording },
        React.createElement(RecordIcon, null),
        React.createElement(RecordingActionText, null, "Start Recording"))) : (React.createElement(StopRecordingButton, { onClick: stopRecording },
        React.createElement(StopIcon, null),
        React.createElement(RecordingActionText, null, "End Recording")));
    var getReadyUI = (React.createElement(GetReadyContainer, null,
        React.createElement(GetReadyBackground, null),
        React.createElement(Div, { w100: true, h100: true, positionAbsolute: true },
            React.createElement(GetReadyContent, null, " GET READY!"),
            React.createElement(GetReadyCountdown, null,
                " ",
                countDown),
            React.createElement(GetReadyCancel, { onClick: cancelCountDown }, "Cancel"))));
    var troubleshootUI = (React.createElement(VideoControls, null,
        React.createElement(TroubleshootText, null, "Please enable camera and microphone access in your web browser\u2019s settings."),
        React.createElement(TroubleshootButton, { onClick: function () {
                if (onTroubleshoot)
                    onTroubleshoot();
            } },
            React.createElement(Icon, { name: "Gear" }),
            React.createElement(Div, { ml_4: true }, "Troubleshoot"))));
    var videoControlsUI = (React.createElement(VideoControls, null,
        isRecording && React.createElement(Stopwatch, { centered: true },
            " ",
            stopwatch),
        countDown > 0 ? (getReadyUI) : (React.createElement(React.Fragment, null, isCameraOpen ? (recordingAction) : (React.createElement(React.Fragment, null, (!initializeOnMount ||
            missingCameraOrMic ||
            !isInlineRecordingSupported) && (React.createElement(React.Fragment, null,
            React.createElement(CameraButton, { onClick: init },
                React.createElement(Icon, { name: "Video" }),
                React.createElement(Div, { ml_7: true }, "Use Camera"))))))))));
    return (React.createElement(React.Fragment, null,
        React.createElement(WorkSansFont, null, "ws"),
        React.createElement(Wrapper, { positionRelative: true, width: width, height: height },
            videoInput,
            videoControlsUI,
            shouldTroubleshoot && troubleshootUI,
            isInlineRecordingSupported && (React.createElement(React.Fragment, null,
                React.createElement(Video, { ref: videoRef, muted: true, playsInline: true, autoPlay: true }),
                isRecording && withVolumeLevel && (React.createElement(VolumeMeterWrapper, { dflex: true, mt_5: true },
                    React.createElement(Meter, { level: volumeLevel * 100 }))))))));
};
var WorkSansFont = styled(Div)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  opacity: 0;\n  font-weight: 700;\n  font-family: Work Sans;\n"], ["\n  position: absolute;\n  opacity: 0;\n  font-weight: 700;\n  font-family: Work Sans;\n"])));
var VolumeMeterWrapper = styled(Div)(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  @media all and ", " {\n    margin-top: -7px;\n  }\n"], ["\n  @media all and ", " {\n    margin-top: -7px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplay; });
var GetReadyContent = styled(Div)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  margin-top: 130px;\n  font-size: 35px;\n  font-weight: 700;\n"], ["\n  margin-top: 130px;\n  font-size: 35px;\n  font-weight: 700;\n"])));
var GetReadyCountdown = styled.div(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  margin-top: 30px;\n  font-weight: 700;\n  font-size: 86px;\n"], ["\n  margin-top: 30px;\n  font-weight: 700;\n  font-size: 86px;\n"])));
var GetReadyCancel = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  margin-top: 90px;\n  font-weight: 700;\n  font-size: 22px;\n  user-select: none;\n  font-family: Overpass, sans-serif;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n"], ["\n  margin-top: 90px;\n  font-weight: 700;\n  font-size: 22px;\n  user-select: none;\n  font-family: Overpass, sans-serif;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n"])));
var GetReadyContainer = styled(Div)(templateObject_6 || (templateObject_6 = __makeTemplateObject(["\n  @media all and ", " {\n    position: fixed;\n    top: 0;\n    left: 0;\n  }\n  height: 100%;\n  width: 100%;\n  color: white;\n  font-family: Work Sans;\n"], ["\n  @media all and ", " {\n    position: fixed;\n    top: 0;\n    left: 0;\n  }\n  height: 100%;\n  width: 100%;\n  color: white;\n  font-family: Work Sans;\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var GetReadyBackground = styled.div(templateObject_7 || (templateObject_7 = __makeTemplateObject(["\n  position: absolute;\n  background-color: black;\n  opacity: 0.6;\n  height: 100%;\n  width: 100%;\n"], ["\n  position: absolute;\n  background-color: black;\n  opacity: 0.6;\n  height: 100%;\n  width: 100%;\n"])));
var Stopwatch = styled(Div)(templateObject_8 || (templateObject_8 = __makeTemplateObject(["\n  border-radius: 30px;\n  height: 40px;\n  position: absolute;\n  top: 30px;\n  @media all and ", " {\n    top: 14px;\n    height: 28px;\n    font-size: 14px;\n  }\n  width: 80px;\n  font-weight: bold;\n  font-family: Roboto, sans-serif;\n  background-color: #120f0b;\n  color: white;\n  font-size: 17px;\n  opacity: 0.7;\n"], ["\n  border-radius: 30px;\n  height: 40px;\n  position: absolute;\n  top: 30px;\n  @media all and ", " {\n    top: 14px;\n    height: 28px;\n    font-size: 14px;\n  }\n  width: 80px;\n  font-weight: bold;\n  font-family: Roboto, sans-serif;\n  background-color: #120f0b;\n  color: white;\n  font-size: 17px;\n  opacity: 0.7;\n"])), function (props) { return props.theme.media.verticalDisplay; });
var RecordIconWrapper = styled.div(templateObject_9 || (templateObject_9 = __makeTemplateObject(["\n  height: 14px;\n  width: 14px;\n  border: solid 2px #df534b;\n  border-radius: 100%;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  @media all and ", " {\n    height: 20px;\n    width: 20px;\n  }\n"], ["\n  height: 14px;\n  width: 14px;\n  border: solid 2px #df534b;\n  border-radius: 100%;\n  padding: 0;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  @media all and ", " {\n    height: 20px;\n    width: 20px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var RedCircle = styled.div(templateObject_10 || (templateObject_10 = __makeTemplateObject(["\n  width: 100%;\n  height: 12px;\n  width: 12px;\n  margin: 0;\n  padding: 0;\n  background-color: #df534b;\n  border-radius: 100%;\n  @media all and ", " {\n    border: solid 2px #df534b;\n    height: 12px;\n    width: 12px;\n  }\n"], ["\n  width: 100%;\n  height: 12px;\n  width: 12px;\n  margin: 0;\n  padding: 0;\n  background-color: #df534b;\n  border-radius: 100%;\n  @media all and ", " {\n    border: solid 2px #df534b;\n    height: 12px;\n    width: 12px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var RecordIcon = function () { return (React.createElement(RecordIconWrapper, null,
    React.createElement(RedCircle, null))); };
var CameraButton = styled.div(templateObject_11 || (templateObject_11 = __makeTemplateObject(["\n  color: white;\n  font-weight: 500;\n  font-size: 17px;\n  width: 140px;\n  svg {\n    path {\n      fill: white;\n    }\n  }\n  background-color: ", ";\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 40px;\n  padding: 0 20px;\n  user-select: none;\n  position: absolute;\n  bottom: 50px;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n  @media all and ", " {\n    bottom: 28px;\n  }\n"], ["\n  color: white;\n  font-weight: 500;\n  font-size: 17px;\n  width: 140px;\n  svg {\n    path {\n      fill: white;\n    }\n  }\n  background-color: ", ";\n  border-radius: 4px;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 40px;\n  padding: 0 20px;\n  user-select: none;\n  position: absolute;\n  bottom: 50px;\n  :hover {\n    cursor: pointer;\n    opacity: 0.7;\n  }\n  @media all and ", " {\n    bottom: 28px;\n  }\n"])), function (props) { return props.theme.colors.blue20; }, function (props) { return props.theme.media.verticalDisplaySmall; });
var TroubleshootText = styled(Div)(templateObject_12 || (templateObject_12 = __makeTemplateObject(["\n  color: white;\n  padding: 20px;\n  @media all and ", " {\n    padding: 0px;\n    margin-top: -120px;\n  }\n"], ["\n  color: white;\n  padding: 20px;\n  @media all and ", " {\n    padding: 0px;\n    margin-top: -120px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var TroubleshootButton = styled(CameraButton)(templateObject_13 || (templateObject_13 = __makeTemplateObject(["\n  color: #1f1f1f;\n  background: white;\n  bottom: 105px;\n  ", " {\n    svg {\n      path {\n        fill: #1f1f1f;\n      }\n    }\n  }\n  @media all and ", " {\n    bottom: 82px;\n  }\n"], ["\n  color: #1f1f1f;\n  background: white;\n  bottom: 105px;\n  ", " {\n    svg {\n      path {\n        fill: #1f1f1f;\n      }\n    }\n  }\n  @media all and ", " {\n    bottom: 82px;\n  }\n"])), Icon, function (props) { return props.theme.media.verticalDisplaySmall; });
var Wrapper = styled(Div)(templateObject_14 || (templateObject_14 = __makeTemplateObject(["\n  height: ", ";\n  width: ", ";\n  background-color: #252a3c;\n  @media all and ", " {\n    background-color: black;\n  }\n"], ["\n  height: ", ";\n  width: ", ";\n  background-color: #252a3c;\n  @media all and ", " {\n    background-color: black;\n  }\n"])), function (props) { return props.height; }, function (props) { return props.width; }, function (props) { return props.theme.media.verticalDisplay; });
var VideoInput = styled.input(templateObject_15 || (templateObject_15 = __makeTemplateObject(["\n  display: none;\n"], ["\n  display: none;\n"])));
var Button = styled(Div)(templateObject_16 || (templateObject_16 = __makeTemplateObject(["\n  font-family: Overpass, sans-serif;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 43px;\n  width: 190px;\n  background-color: white;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  border-radius: 35px;\n  font-weight: 600;\n  font-size: 17px;\n  user-select: none;\n  position: absolute;\n  bottom: 33px;\n  @media all and ", " {\n    bottom: 12px;\n    width: 50px;\n    height: 50px;\n    padding: 0;\n    margin: 0;\n  }\n  :hover {\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"], ["\n  font-family: Overpass, sans-serif;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  height: 43px;\n  width: 190px;\n  background-color: white;\n  box-shadow: 0 8px 20px 0 rgba(51, 72, 115, 0.13);\n  border-radius: 35px;\n  font-weight: 600;\n  font-size: 17px;\n  user-select: none;\n  position: absolute;\n  bottom: 33px;\n  @media all and ", " {\n    bottom: 12px;\n    width: 50px;\n    height: 50px;\n    padding: 0;\n    margin: 0;\n  }\n  :hover {\n    cursor: pointer;\n    opacity: 0.9;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var RecordingActionText = styled(Div)(templateObject_17 || (templateObject_17 = __makeTemplateObject(["\n  margin-left: 9px;\n  @media all and ", " {\n    display: none;\n  }\n"], ["\n  margin-left: 9px;\n  @media all and ", " {\n    display: none;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var StopRecordingButton = styled(Button)(templateObject_18 || (templateObject_18 = __makeTemplateObject(["\n  background-color: #df534b;\n  color: white;\n  :hover {\n    background-color: #831713;\n  }\n"], ["\n  background-color: #df534b;\n  color: white;\n  :hover {\n    background-color: #831713;\n  }\n"])));
var StopIcon = styled.div(templateObject_19 || (templateObject_19 = __makeTemplateObject(["\n  height: 15px;\n  width: 15px;\n  background-color: white;\n  border-radius: 3px;\n  @media all and ", " {\n    height: 21px;\n    width: 21px;\n  }\n"], ["\n  height: 15px;\n  width: 15px;\n  background-color: white;\n  border-radius: 3px;\n  @media all and ", " {\n    height: 21px;\n    width: 21px;\n  }\n"])), function (props) { return props.theme.media.verticalDisplaySmall; });
var VideoControls = styled(Div)(templateObject_20 || (templateObject_20 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  z-index: 1;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  text-align: center;\n  z-index: 1;\n  position: absolute;\n  height: 100%;\n  width: 100%;\n"])));
var Video = styled.video(templateObject_21 || (templateObject_21 = __makeTemplateObject(["\n  outline: none;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n"], ["\n  outline: none;\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n"])));
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5, templateObject_6, templateObject_7, templateObject_8, templateObject_9, templateObject_10, templateObject_11, templateObject_12, templateObject_13, templateObject_14, templateObject_15, templateObject_16, templateObject_17, templateObject_18, templateObject_19, templateObject_20, templateObject_21;
