import { Section } from "./common";
import { Div, RadioInput } from "@max/common-ui";
import { useFormikContext } from "formik";
import { ShoutoutAllocationMode } from "@musicaudienceexchange/shoutout-interface/lib/firestore/shoutouts";
import { campaignTypeOptions } from "./common/initialValues";

const header = "Plan New Campaign";
const description =
  "Choose one of the options below to specify whether there are any limitations on a fan's ability to submit a ShoutOut request.";

const CampaignTypeField = () => {
  const { values, setValues } = useFormikContext<any>();
  return (
    <Section header={header} description={description}>
      <Div mt_28>
        <RadioInput
          name="type"
          options={campaignTypeOptions}
          onSelect={(value) => {
            setValues({
              ...values,
              campaignEndDate:
                value === ShoutoutAllocationMode.date
                  ? values.campaignEndDate
                  : null,
              maxNumberRequests:
                value === ShoutoutAllocationMode.quantity
                  ? values.maxNumberRequests
                  : null,
              type: value,
            });
          }}
        />
      </Div>
    </Section>
  );
};

export default CampaignTypeField;
