import React from "react";
import ChromeDefault from "../../../../assets/images/ChromeDefault.png";
import FirefoxDefault from "../../../../assets/images/FirefoxDefault.png";
import { ImageLogo } from "./windows";
var imagePath = "".concat(process.env.REACT_APP_ASSETS_PATH, "/troubleshoot/android");
export var android = [
    {
        key: "chrome",
        name: "Chrome",
        logo: React.createElement(ImageLogo, { src: ChromeDefault }),
        data: [
            {
                type: "text",
                content: "Allow access to camera and microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/1.png"),
            },
            {
                type: "text",
                content: "Or, click the lock icon to go to Site settings",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/2.png"),
            },
            {
                type: "text",
                content: "Click Access your camera and choose Allow",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/3.png"),
            },
            {
                type: "text",
                content: "Click Access your microphone and choose Allow",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/chrome/4.png"),
            },
        ],
    },
    {
        key: "firefox",
        name: "Firefox",
        logo: React.createElement(ImageLogo, { src: FirefoxDefault }),
        data: [
            {
                type: "text",
                content: "Allow Firefox access to camera and microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/1.png"),
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/2.png"),
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/3.png"),
            },
            {
                type: "text",
                content: "Or, Click the three vertical dots and then choose Settings",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/4.png"),
            },
            {
                type: "text",
                content: "Under Privacy and security, click Site permissions",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/5.png"),
            },
            {
                type: "text",
                content: "Allow access to Camera and Microphone",
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/6.png"),
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/7.png"),
            },
            {
                type: "image",
                content: "".concat(imagePath, "/firefox/8.png"),
            },
        ],
    },
];
